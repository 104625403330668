$panel-body-border: 1px solid #ddd;

.role_lms_container {
  .rht-content-panel {
    border: none;

    .panel-heading {
      border: 1px solid transparent;

      h3 {
        margin: 5px 0;
      }

      span.toggle-icon:after {
        font-family: "Glyphicons Halflings";
        content: "";
      }
    }

    .panel-body {
      border: $panel-body-border;
      border-top: none;
    }
  }

  ul.nav-pills {
    background-color: black;
    color: white;

    li a {
      color: white;
      border-radius: 0;

      &:hover, &:active {
        color: #646464;
      }
    }

    li.active a {
      background-color: white;
      color: black;
    }
  }
}
