@import '../../../../../styles/variables';

$show_solution_margin: 0.6em;

.show-solution-interaction {
  .section .prompt {
    white-space: pre-wrap;
  }

  .section .prompt:not(:first-child):before {
    content: '\A';
  }

  .emphasis em::after {
    content: '\A';
    white-space: pre-wrap;
  }

  .step {
    .panel-heading {
      background-color: #f5f5f5;
      border-color: #ddd;
    }

    .panel-collapse {
      visibility: hidden;
      height: 0;
      overflow: hidden;
      display: block;

      &.collapsing {
        visibility: visible !important;
      }

      &.in {
        visibility: visible;
        height: initial;
      }
    }

    .panel-body {
      border: 0;
      padding: 0 $form-padding;
      margin-top: $show_solution_margin;
    }
  }

  // Steps and substeps
  ol.procedure[type='1'] {
    & > li.step {
      counter-increment: item;
      margin-bottom: $show_solution_margin;
      padding-left: $show_solution_margin;
      margin-left: 20px;
    }
  }

  // Steps and substeps for procedure that should be wrapped under solution
  ol.procedure[type='2'] {
    padding-left: 10px;

    & > li.step {
      counter-increment: item;
      margin-bottom: $show_solution_margin;
      padding-left: $show_solution_margin;
    }
  }

  ol.substeps[type='1'] {
    display: table;
    width: 100%;
    list-style-type: none;
    counter-reset: item;
    padding-left: 0;

    & > li.step {
      display: table-row;
      counter-increment: item;
      margin-bottom: $show_solution_margin;

      &:before {
        content: counters(item, '.') '. ';
        padding-right: $show_solution_margin;
        display: table-cell;
        width: 0.1%;
      }
    }
  }
}
