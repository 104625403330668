@import '../../styles/mixins/mediaqueries';
@import '../../styles/variables';

.landing-mat {
  --pf-v5-global--primary-color--100: #{$color-primary};
  --pf-v5-global--primary-color--200: #{$color-primary-hover};
  position: relative;
  display: flex;
  justify-content: center;
  flex: 1;
  padding: var(--pf-v5-global--spacer--2xl);

  background: url('../../img/bubbles.svg') no-repeat 0 0,
  var(--pf-v5-global--palette--black-1000);
  background-position: right bottom;

  @include respond(sm, false) {
    padding: var(--pf-v5-global--spacer--md);
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-auto-rows: max-content;
    gap: var(--pf-v5-global--spacer--xl);
    flex: 1;
    max-width: var(--pf-v5-global--breakpoint--xl);

    @include respond(md, false) {
      grid-template-columns: 1fr;
    }
  }

  &__card {
    min-height: 20vh;
    grid-column: span 2;

    &--registration {
      grid-column: span 6;
    }

    @include respond(xl, false) {
      grid-column: span 6;
    }
  }

  &__login-card {
    grid-column: span 6;
  }

  &__registration-body {
    display: flex;
    flex-direction: row;

    @include respond(lg, false) {
      flex-direction: column;
    }
  }

  &__video-container {
    width: 40%;
    flex-shrink: 0;
    padding: 0 var(--pf-v5-global--spacer--lg);

    @include respond(lg, false) {
      width: 100%;
      padding: 0;
    }
  }

  &__tutorial-video {
    width: 100%;
  }
}
