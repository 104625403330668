@use 'sass:math';
@import '../../../styles/mixins/mediaqueries';

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@media print {
  body {
    print-color-adjust: exact;
  }

  .dashboard-wrapper {

    // work-around to force boostrap columns NOT to behave like they would on mobile view
    @for $i from 1 through 12 {
      .print-span-#{$i} {
        --pf-v5-l-grid__item--GridColumnEnd: span #{$i};
      }
    }

    & .card-info,
    .medium-card-info {
      font-size: 12px !important;
    }

    & .giant-nr {
      font-size: 30px !important;
    }

    // make sure all chart tabs are visible at the same time
    .pf-v5-c-tab-content {
      display: block;
    }

    // prevent fade-in animation from hiding chart
    .chart-fade-in {
      animation: none !important;
    }

    // show chart titles
    .chart-print-title {
      display: block !important;
      text-align: center !important;
      background-color: #f8f8f8 !important;
      padding: 4px;
      width: 100%;
    }

    // hide components like filters, search bar, pagination, info buttons...
    .course-progress__filters,
    .course-progress__filter,
    .pf-v5-c-pagination,
    .pf-v5-c-tabs,
    .pf-v5-c-button.pf-m-plain,
    .expandable-section__arrow-icon {
      display: none;
    }

    // prevent blank space at the bottom of report
    .course-progress__table-container--with-min-height {
      min-height: auto;
    }

    // make full usage of page width, preventing text from wrapping in most cases
    .dashboard-wrapper__print-width-guarantee {
      display: block;
      height: 1px;
      opacity: 0;
      min-width: 130vw;
    }

    .expandable-section__clickable-strip--light {
      background-color: #f8f8f8 !important;
    }

    .expandable-section {
      margin-top: 36px;
      page-break-before: always;
    }

    // adjust chart scale and font size for printable versions
    .pf-v5-c-chart {
      & tspan {
        font-size: 14px !important;
      }
    }

    // firefox workarounds:
    @-moz-document url-prefix() {

      // prevent tables from overlaping
      .expandable-section {
        page-break-before: always;
        margin-top: 0;
      }
    }
  }
}

.dashboard-summary-download-menu {
  display: flex;
  justify-content: flex-end;
}

.dashboard-wrapper {
  animation: fade-in 300ms ease-in-out;

  &__print-width-guarantee {
    display: none;
  }

  .mini-card {
    .card-data {
      position: relative;
      height: 60px;
      border: 1px solid #dddddd;

      .card-icon {
        width: 60px;
        height: 60px;
        float: left;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .icon-1 {
        background: #20a8d8;
      }

      .icon-2 {
        background: #63c2de;
      }

      .icon-3 {
        background: #ffc107;
      }

      .icon-4 {
        background: #f86c6b;
      }

      .icon-5 {
        background: #50bfb4;
      }

      .card-course-icon {
        background: #50A3B0;
      }

      .card-lesson-icon {
        background: #4E745C;
      }

      .card-info {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        padding-left: 10px;

        .bold-data {
          font-weight: bold;
        }
      }

      .videos-info-button {
        position: absolute;
        right: 0;
      }

      .premium-info-button {
        position: absolute;
        right: 0;
      }
    }

    .flip-mini-card {
      background-color: transparent;
      height: 60px;
      perspective: 1000px;

      /* Remove this if you don't want the 3D effect */
    }

    /* This container is needed to position the front and back side */
    .flip-mini-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      // text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip-mini when you move the mouse over the flip-mini box container */
    .flip-mini-card:hover .flip-mini-card-inner {
      transform: rotateX(180deg);
    }

    /* Position the front and back side */
    .flip-mini-card-front,
    .flip-mini-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;

      /* Safari */
      backface-visibility: hidden;

      p.scaled-text {
        line-height: 95%;
      }
    }

    /* Style the back side */
    .flip-mini-card-back {
      padding: 10px 15px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #f8f8f8;
      transform: rotateX(180deg);
      border: 1px solid #dddddd;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }

  & .mt-16 {
    margin-top: 16px;
  }

  .medium-card {
    .medium-card-data {
      .medium-card-info {
        height: 150px;
        border: 1px solid #dddddd;

        &--auto-height {
          height: auto;
        }

        .medium-card-display {
          height: 110px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;

          &--auto-height {
            height: auto;
          }

          .exam-data-wrapper {
            width: 80%;

            .exam-data-holder {
              display: flex;

              .exam-data {
                margin-left: auto;
              }

              .bold-data {
                font-weight: bold;
              }
            }
          }

          .lab-hours-summary-wrapper {
            width: 80%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .inline-count-up {
              display: flex;
              flex-direction: row;
              font-size: 24px;
            }

            .progress {
              overflow: hidden;
              height: 10px;
              background-color: #f5f5f5;
              width: 100%;
              margin-bottom: 0;
              margin-top: 10px;
              border-radius: 2px;

              .progress-bar {
                text-align: right;
                background-color: #08c0fc;
              }
            }
          }
        }

        .medium-card-top {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-end;
        }

        .medium-card-bottom {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top: solid 1px #dddddd;
        }

        .medium-nr {
          font-size: 50px;
          font-weight: bold;
        }

        .greyed-nr {
          color: #d8d8d8;
        }

        .giant-nr {
          font-size: 45px;
          font-weight: bold;
        }

        .green-text {
          color: #4caf50;
        }
      }
    }

    .flip-card {
      background-color: transparent;
      height: 150px;
      perspective: 1000px;

      /* Remove this if you don't want the 3D effect */
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card:hover .flip-card-inner {
      transform: rotateY(180deg);
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;

      /* Safari */
      backface-visibility: hidden;
    }

    /* Style the back side */
    .flip-card-back {
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      transform: rotateY(180deg);
      border: 1px solid #dddddd;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }

  .chart-fade-in {
    animation: fadeIn 800ms ease;
  }

  .chart-print-title {
    display: none;
  }

  &__loading-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20vh 0px;
  }
}
