@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';
@import '../../../components/PremiumFilter/styles';

.schedule-container {
  margin-top: 15px;

  .schedule-list-container {
    display: inline-block;
    width: 100%;
  }

  .schedule-list-wrapper {
    @media (max-width: $screen-sm-max) {
      border-top: 1px solid #b7b7b7;
    }

    .session-row {
      // min-height: 100px;
      border-right: 1px solid #b7b7b7;
      border-left: 1px solid #b7b7b7;
      border-bottom: 1px solid #b7b7b7;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 2px 0;

      .col-sm-2 {
        display: none;

        .sp-sm-header {
          color: $gray-dark;
          margin-right: 50px;
        }
      }

      .session-column-row {
        flex: 1;

        label {
          font-weight: normal;
          margin-bottom: unset;
        }
      }

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
        align-items: stretch;
        padding-top: 20px;

        .rd-session-radio {
          display: none;
        }

        .col-sm-2 {
          display: inline;
        }
      }
    }

    .full {
      background-color: #dedede;
    }

    .selected {
      background-color: #c2d8ea;
    }

    .disabled {
      color: $gray-lighter;
    }
  }

  .schedule-list-header {
    display: flex;

    // .date-sorting {
    //   // width: 60%;
    // }
    @media (max-width: $screen-sm-max) {
      display: none;
    }

    .column-sort {
      background-color: #ffffff;
      border-bottom: 1px solid #b7b7b7;
      border-right: 1px solid #b7b7b7;
      border-left: 1px solid #b7b7b7;
      border-top: 1px solid #b7b7b7;
      flex-direction: row;

      flex: 1;

      .column-sort-wrapper {
        padding-left: 15px;
        display: flex;

        flex-direction: row;
        line-height: 35px;
      }

      .sort-btn {
        cursor: pointer;

        outline: none;
        margin: auto;
        margin-right: 0;
        display: flex;
        align-items: center;

        svg {
          height: 20px;
        }
      }
    }
  }

  .schedule-pagination {
    text-align: center;
  }

  .schedule-buttons {
    text-align: right;

    .cancel-reschedule-button {
      margin-right: 15px;
    }

    .view-loader-wrapper {
      width: 100%;
      height: 38px;
      border: 1px dashed #000000;
      border-radius: 0.25rem;

      .spinner-wrapper {
        transform: scale(0.7, 0.7);
      }
    }

    .schedule-reschedule-btn {
      margin-bottom: var(--pf-v5-global--spacer--md);
    }
  }
}

.schedule-progress-modal-dialog {
  .modal-content,
  .modal-body,
  .modal-header {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .modal-title {
    color: rgb(101, 101, 101);
  }

  .close-btn {
    background-color: #08c0fc;
    color: #ffffff;
    border: 1px solid #08c0fc;
    border-radius: 0.25rem 0.25rem;
  }

  .clear-btn {
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    border-radius: 0.25rem 0.25rem;
    margin-right: 15px;
    margin-left: 15px;
    text-align: right;
  }

  .success-message {
    color: #4caf50;
  }

  .error-message {
    color: red;
  }

  .model-wrapper {
    .progress-message {
      display: flex;
    }

    .progress-message-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;
    }
    @media (min-width: 576px) {
      .progress-form {
        min-width: 570px;
      }
    }
  }
}

.subscription-expired-modal {
  .modal-content,
  .modal-body,
  .modal-header {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .modal-title {
    color: rgb(101, 101, 101);
  }

  .subscription-expired-modal-close-button {
    text-align: right;
  }
}
