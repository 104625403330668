.lab_alert {
  margin-bottom: 20px;
  align-items: center;

  p {
    font-size: 15px;
  }
}

.lab_usage_alert {
  & h4 {
    margin-top: 0px;
  }
}
