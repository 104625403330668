@import '../../../styles/_variables.scss';

.subscription-widget {
  text-align: center;
  background-color: $gray-lightest;

  .subscription-info {
    text-decoration: none;
    list-style-type: none;
    padding: 5px 0;
    margin: 0;

    li {
      font-weight: 300;
      display: inline-block;
      padding: 5px;
    }

    .usage-alert-bg {
      background-color: #ebcc5e1a;
    }

    .usage-alert {
      color: #8e6506;
    }

    .usage-info-alert {
      color: #004d99;
    }

    .exclamation-triangle-icon {
      top: 3px;
    }
  }
}
