@import '../../../styles/variables';
@import '../../../styles/mixins/mediaqueries';

.organization-form {
  padding-top: var(--pf-v5-global--spacer--xl);
  padding-right: var(--pf-v5-global--spacer--3xl);
  padding-left: var(--pf-v5-global--spacer--3xl);

  &__select {
    max-width: 400px;
  }

  &__custom-date-modal {
    & h1 {
      margin: 0;
    }
  }

  &__custom-date-modal-body {
    display: flex;
    align-items: flex-start;
    gap: var(--pf-v5-global--spacer--md);
    padding-block-start: var(--pf-v5-c-modal-box__body--PaddingTop);
    padding-inline-start: var(--pf-v5-c-modal-box__body--PaddingLeft);
    padding-inline-end: var(--pf-v5-c-modal-box__body--PaddingRight);

    & > span {
      margin-top: var(--pf-v5-global--spacer--sm);
    }
  }
}
