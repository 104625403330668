@import '../../styles/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../styles/mixins/mediaqueries';

$panel-width: 400px;

.drawer-component {
  position: relative;
  width: 100%;

  .loading {
    text-align: center;
  }

  .section-wrapper {
    list-style: none;
    width: 100%;
    padding: 0;

    .section-header {
      background-color: white;

      .section-header-btn {
        background-color: inherit;
        transition: all 200ms ease;
        padding: 12px;

        &:focus {
          outline: none;
        }

        &:hover {
          background-color: #e0e0e0;
        }

        &.all {
          border-top: 1px solid #aaa;
        }
      }

      .active {
        color: white;
        background-color: $secondary-red-color;

        &:hover {
          background-color: darken($secondary-red-color, 6%);
        }
      }

      .archives {
        &:hover {
          background-color: transparent;
        }
      }
    }
  }

  .drawer-section {
    height: 100%;
    overflow-y: auto;

    .reports-msg {
      text-align: center;
      margin: 15px;
    }
  }

  /* Position and sizing of burger button */
  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #373a47;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu-wrap {
    z-index: 9999;
    width: $panel-width !important;
    top: 0;


    @include respond(md, false) {
      width: 100% !important;
    }
  }

  /* General sidebar styles */
  .bm-menu {
    background: #f8f8f8;
    font-size: 1.15em;
    overflow-y: hidden !important;


    @include respond(md, false) {
      padding: 12px;
    }


    &.opened {
      box-shadow: -10px 10px 30px -10px #6666668f
    }

    .bm-item {
      outline: unset;
      width: 100%;
      text-align: center;
      padding-bottom: 75px;
    }

    @media (max-width: $screen-xs-min) {
      width: 100% !important;
    }
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    display: flex;
    justify-content: center;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: transparent !important;
    top: 0;
  }
}
