///
/// @file Course Catalog store
/// @author Joshua Jack <jjack@redhat.com>
///

@import '../../styles/variables';
@import '../../components/Catalog/Filters/styles';
@import "../../styles/mixins/mediaqueries";

.catalog-list {
  display: inline-block;
  transition: 0.2s;
  padding-right: 0;

  @include respond(lg, false) {
    padding-left: 0;
  }
}
