@import '../../../styles/variables';

.tabContent {
  .tocContent {
    width: 100%;

    td {
      padding-top: 10px;
    }

    .toc_entry_depth_2 {
      padding-left: 20px;
      background-color: $gray-lighter;

      a {
        color: #646464;

        &:visited,
        &:hover,
        &:active {
          color: #646464;
        }
      }
    }

    .toc_entry_depth_3 {
      padding-left: 40px;

      a {
        color: #337ab7;

        &:hover,
        &:focus,
        &:active {
          color: #23527c;
        }
      }

      .active {
        font-weight: bold;
      }
    }

  }
}
