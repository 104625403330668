@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.mgmt-csk-container {
  display: flex;
  flex-direction: column;

  .display-flex {
    display: flex;
  }

  .user-list-pagination {
    text-align: center;
  }
}
