@import '../../styles/animations';

.aws-waf-captcha {
  --pf-v5-c-modal-box--c-button--sibling--MarginRight: 0;

  &__container {
    min-height: 660px;
    animation: fade-in 0.5s ease-in;
    animation-fill-mode: backwards;
    overflow: auto;
  }
}
