.expandable-section {
  &__clickable-strip {
    display: flex;
    padding: 12px 16px;
    margin-bottom: 10px;
    justify-content: space-between;
    cursor: pointer;

    &--dark {
      background: #3c3f42;
      color: white;

      & svg {
        fill: white;
      }
    }

    &--light {
      background: #F8F8F8;
      color: black;
      border-bottom: 1px solid #B7B7B7;

      & svg {
        fill: black;
      }
    }
  }

  &__arrow-icon {
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 20px;
      height: 20px;
    }
  }
}
