@import '../../styles/mixins/mediaqueries';

.widget-wrapper {
  margin-top: 32px;
  display: flex;
  flex-direction: row;

  @include respond(md, false) {
    flex-direction: column;
  }
}

.my-widgets {
  display: inline-block;
  flex-direction: column;
  flex: 1;
  align-items: center;
  transition: 0.2s;
}

.my-casses-widget,
.learning-path-widget {
  width: 100%;
}

.syndication-widget {
  display: inline-block;
  vertical-align: top;
  transition: 0.2s;
  margin-right: 0;
  padding-left: 0;
  flex: 1;
  max-width: 320px;
  margin-left: 15px;

  @include respond(md, false) {
    max-width: 100%;
    margin-bottom: 15px;
    margin-left: 0;
  }
}

.tour-wrapper {
  .overlay {
    height: 100%;
    width: 100%;
    position: fixed;
    z-index: 7000;
    top: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    background-color: rgba(0, 0, 0, 0.9);
    overflow: hidden;
    transition: 0.5s;
  }

  .overlay-content {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 75%;
    margin-top: 30px;
  }

  .overlay-content button {
    margin-top: 15px;
  }
}

.home-free-trial-wrapper {
  padding-bottom: calc(4 * var(--pf-v5-global--spacer--2xl));
}
