

.video-container {
  margin-top: 40px;
  color: white;

  .video-handler {
    display: flex;
    padding: 8px 16px;
    margin-bottom: 10px;
    justify-content: space-between;
    background: #3C3F42;
    cursor: pointer;

    .icon-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    svg {
      width: 20px;
      height: 20px;
      fill: white;
    }
  }

  .expand-video {
    .kaltura-widget-wrapper {
      .the-kaltura-player {
        aspect-ratio: 16 / 9;
      }
    }
  }

  .picture-in-picture-video {
    .the-kaltura-player {
      height: 90px !important;
    }
  }

  .collapse-video {
    .the-kaltura-player {
      height: 0px !important;
    }
  }
}
