#search-results-tabs {
  margin-top: 10px;

  .catalog-search-results-container, .learning-paths-search-results-container, .all-search-results-container {
    margin-top: 20px;
    transition: 0.2s;

  }

  .suggestion-container {
    font-size: large;
  }
}
