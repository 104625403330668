.dashboard-wrapper {
  .dashboard-mainsection-wrapper {
    margin-top: 20px;

    .dashboard-search {
      background-color: #ffffff;
      border-top: 1px solid #b7b7b7;
      border-right: 1px solid #b7b7b7;
      border-left: 1px solid #b7b7b7;
      height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 0 15px;

      input.form-control {
        font-size: inherit;
        font-weight: 300;
      }
    }

    .dashboard-filter {
      min-height: 60px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      padding: 10px 15px 5px 15px;
      border: 1px solid #b7b7b7;
      background-color: #ffffff;

      .react-selectize.root-node {
        width: 100%;
      }

      .offering-selector {
        padding-bottom: 10px;
      }
    }

    .dashboard-sort {
      background-color: #ffffff;
      border-bottom: 1px solid #b7b7b7;
      border-right: 1px solid #b7b7b7;
      border-left: 1px solid #b7b7b7;
      height: 60px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      .offerings-sort-wrapper {
        padding-left: 15px;
        display: flex;
        justify-content: start;
        flex-direction: row;
        line-height: 35px;
      }

      .title-sorting {
        width: 60%;
      }

      .last-visited-sorting {
        width: 40%;
      }

      .date-sort-wrapper {
        padding-left: 15px;
        width: 40%;
        display: flex;
        justify-content: start;
        flex-direction: row;
      }

      .sort-btn {
        margin-left: 15px;
        cursor: pointer;
        height: 35px;
        outline: none;
      }
    }

    .dashboard-main-list {
      padding: 15px 0px 0 0px;

      .padding-row {
        padding-left: 15px;
        padding-right: 15px;
      }
      
    }
  }

  .view-loader-wrapper {
    margin-top: 20px;
  }
}
