.scheduled-reports {
  &__form {
    max-width: 1000px;
  }

  &__fields {
    border: 1px solid var(--pf-v5-global--BorderColor--100);
    background-color: var(--pf-v5-global--palette--black-150);
    padding: var(--pf-v5-global--spacer--md);

    --pf-v5-c-form--m-horizontal__group-label--md--GridColumnWidth: calc(100% / 3) !important;
  }
}
