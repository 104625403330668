// Selectors
// Red Hat Brand color palette
$color-primary: #ee0000;
$color-secondary: #004153;
$color-secondary-light: #007a87;
$color-secondary-dark: #004254;
$color-accent-blue: #0088ce;
$color-tertiary: #51a5b1;
$color-light-purple: #4e76b0;
$color-white: #ffffff;
$color-green: #479b2e;
$color-course: #50a3b0;
$color-class: $color-secondary-light;
$color-expert-seminar: #5073b0;
$color-learning-path: #5f526d;
$color-exam: #004153;
$color-prelim-exam: #2274a5;
$color-remote-exam: #4e6766;
$color-lab: #eda502;

// PF + Branding guide color palette
$brand-primary: #0066cc;
$brand-primary-hover: #004080;
$brand-primary-light: #73bcf7;
$brand-danger: #c9190b;

// Red Hat Web Standard
$color-ws-cancel-dark: #6a6e73;
$color-ws-cancel-light: #d2d2d2;

$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%); // #222
$gray-dark: #252527;
$gray: #646464;
$gray-light: lighten($gray-base, 46.7%); // #777
$gray-lighter: #d2d3d5;
$gray-lightest: #eee;

// Bootstrap overrides
$text-color: #4c4c4c;
$link-color: $brand-primary;
$link-color-hover: $brand-primary-hover;
$btn-primary: $color-primary;
$btn-info-bg: $color-white;
$btn-info-color: $brand-primary;
$btn-info-border: $brand-primary;

// Buttons
$btn-secondary: $color-secondary-light;
$btn-secondary-border: darken($color-secondary-light, 5%);

// Fonts
$font-path: '../fonts/';
$img-path: '../img/';
$icon-font-path: $font-path;

$font-family-sans-serif: 'RedHatDisplay', 'Overpass', 'Open Sans', Helvetica, sans-serif;

$base_selector: '.role_lms_container';
$page_selector: '.rht_rhls_page';
$seminar_selector: '.rht_rhls_seminar';

// Colors
$color-white: #fff;
$secondary-red-color: #c9190b;
$color-primary-hover: #a30000;
$dark-text: #646464;
$early-access-primary-color: #3f9c35;

// Favorites
$favorite-icon-color: #ffbf04;
$favorite-icon-font-size: 24px;

// Notes
$note-default-color: #9fcbd1;
$note-references-color: #c9e5f2;
$note-important-color: #eb8b38;
$note-warning-color: #d71a3a;
$note-comparison-color: $note-default-color;

// Forms
$form-padding: 15px;

$border-color: #090808;
$background-color: #222;
$grid-distance: 10px;
