@import '../../../styles/variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';

$panel-width: 400px;

.feedback-component {
  position: relative;
  width: 100%;
  padding: 0 15px 15px;
  text-align: left;

  .feedback-toggle {
    padding: 5px;
    cursor: pointer;
  }

  .control-label {
    font-weight: 400;
    font-size: small;
  }

  .feedback-img-wrapper {
    display: flex;
    justify-content: center;
    background: #aaaaaa;
    margin-bottom: 15px;
  }

  .loading {
    text-align: center;
  }

  #feedbackTextArea {
    min-height: 100px;
  }
}
