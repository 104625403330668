@import "../../../styles/mixins/mediaqueries";
@import "../../../styles/variables";

$visited: scale-color($brand-primary, $saturation: -35%);
$visited-hover: #3980C6;

.progress-map {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-direction: normal;
  -webkit-box-orient: horizontal;
  -webkit-flex-direction: row;
  -moz-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: left;
  -moz-justify-content: left;
  justify-content: left;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-flex-line-pack: center;
  align-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  align-items: center;
  min-height: 30px;
  padding: 0;
  margin-bottom: 20px;
  list-style: none;
  background-color: #f5f5f5;
  border-radius: 4px;
  margin-top: 15px;

  .progress-map-bar {

    cursor: pointer;
    background-color: #9CBFE2;
    border-right: 1px solid #fff;
    color: #fff;
    text-align: center;
    -webkit-box-ordinal-group: 1;
    -webkit-order: 0;
    -moz-order: 0;
    -ms-flex-order: 0;
    order: 0;
    -webkit-box-flex: auto;
    -webkit-flex: auto;
    -moz-box-flex: auto;
    -moz-flex: auto;
    -ms-flex: auto;
    flex: auto;
    -webkit-align-self: auto;
    -moz-align-self: auto;
    -ms-flex-item-align: auto;
    align-self: auto;
    -webkit-transition: background-color .3s;
    -o-transition: background-color .3s;
    transition: background-color .3s;
    height: 18px;
    font-size: .8em;
    margin: 2px 0;

    &:hover {
      background-color: $visited;
    }

    &.visited {
      background-color: $visited;

      &:hover {
        background-color: $visited-hover;
      }
    }

    &.preface {

      text-align: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0;
      -moz-box-flex: 0;
      -moz-flex: 0;
      -ms-flex: 0;
      flex: 0;
      min-width: 24px;
      height: 22px;
      background-color: #7DA9D4;
    }

    &.is-active {
      background-color: #c00;

      &:hover {
        background-color: #b30000;
      }
    }

    &.chapter {
      text-align: center;
      -webkit-box-flex: 0;
      -webkit-flex: 0;
      -moz-box-flex: 0;
      -moz-flex: 0;
      -ms-flex: 0;
      flex: 0;
      min-width: 24px;
      height: 22px;
      background-color: #78acd9;
    }

    &.disabled {
      background-color: #ccc;
    }

    @include respond(sm, false) {
      max-width: 42px;
      flex-basis: 42px;
    }

    @include respond(sm) {
      max-width: 1%;
      flex-basis: 1%;
    }
  }
}