#consent_blackbar {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 5;
  padding: 0;

  a {
    color: #08c0fc;

    &:hover {
      color: #0289b5;
    }
  }
}
