@import '../../../styles/mixins/mediaqueries';

.hud-course-view {
  padding-top: var(--pf-v5-global--spacer--xl);
  margin-bottom: var(--pf-v5-global--spacer--xl);
  min-height: 95vh;

  &__toolbar {
    animation: fade-in 300ms ease-in-out;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: var(--pf-v5-global--spacer--lg) 0;
    flex-wrap: wrap;

    @include respond(md, false) {
      flex-direction: column;
    }
  }

  &__toolbar-left {
    display: flex;
    flex-direction: row;
  }

  &__toolbar-right {
    display: flex;
    flex-direction: row;
    gap: var(--pf-v5-global--spacer--md);
    flex-wrap: wrap;
    align-items: flex-end;

    @include respond(sm, false) {
      flex-direction: column;
      align-items: stretch;
    }
  }

  &__tabs {
    & > ul {
      // workaround bootstrap default margin:
      margin-bottom: 0;
    }
  }

  &__tab-content {
    padding-top: var(--pf-v5-global--spacer--lg);
  }

  &__bookmark {
    min-height: 32px;
  }

  &__progress-map {
    min-height: 52px;
  }

  &__fade-in {
    animation: fade-in 400ms ease-in;
    animation-fill-mode: backwards;
    animation-delay: 150ms;

    &--delayed {
      animation-delay: 250ms;
    }
  }

  &__video-widget {
    animation: move-in-from-top 400ms ease-in-out;
    animation-fill-mode: backwards;
    animation-delay: 150ms;
  }

  &__hud {
    p,
    h1 {
      margin: 0;
    }
  }
}

.body-intercom-for-hud {
  @media (max-width: 1024px) {
    .intercom-lightweight-app-launcher {
      bottom: 90px !important;
    }

    *:has(.intercom-launcher-frame) {
      bottom: 90px !important;
    }

    /*localhost*/
    .intercom-dfosxs {
      bottom: 90px !important;
    }

    iframe[name='intercom-notifications-frame'] {
      bottom: 124px !important;
    }
  }
}
