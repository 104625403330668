@import '../../../styles/mixins/mediaqueries';

.live-session-calendar {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  &__content {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    @include respond(md, false) {
      flex-direction: column-reverse;
    }
  }

  &__results-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 2;
    padding: var(--pf-v5-global--spacer--md);
    padding-bottom: var(--pf-v5-global--spacer--4xl);
    background-color: #f6f7f9; // TODO: Use patternfly variable
  }

  &__results {
    display: flex;
    justify-content: center;
    flex: 2;
    background-color: transparent;
  }

  &__filters {
    background-color: #f0f1f4; // TODO: Use patternfly variable

    @include respond(md, false) {
      background-color: #e2e4e9; // TODO
    }
  }

  &__loading-container {
    display: flex;
    flex: 1;
    height: 100%;
    align-items: center;
    justify-content: center;
  }

  &__back-to-top {
    position: fixed !important;
    left: var(--pf-v5-c-back-to-top--Right) !important;
    right: unset !important;
    z-index: 20 !important;

    @include respond(md, false) {
      left: var(--pf-v5-global--spacer--lg);
    }

    .pf-v5-c-button__icon {
      margin-left: 0;
    }
  }

  &__chapter-list {
    margin-bottom: 0;
  }

  &__in-progress-label {
    position: absolute;
    top: var(--pf-v5-global--spacer--sm);
    right: var(--pf-v5-global--spacer--sm);
  }

  &__action-btn {
    --pf-v5-c-button--m-tertiary--BackgroundColor: white;
    --pf-v5-c-button--m-tertiary--hover--BackgroundColor: #f0f1f4;
    --pf-v5-c-button--m-tertiary--focus--BackgroundColor: #f0f1f4;
    --pf-v5-c-button--m-tertiary--active--BackgroundColor: #f0f1f4;
  }
}
