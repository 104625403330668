@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.admin-dashboard-wrapper {
  margin-bottom: 100px;

  .svg-holder span:focus {
    outline: none;
    box-shadow: none;
  }
}

.form-select {
  border: 0;
  background-color: transparent;
}

.user-search-bar {
  margin-right: 950px;
}
