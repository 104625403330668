@import "../../../styles/mixins/mediaqueries";

.ptl-lab-component {
  margin: var(--pf-v5-global--spacer--xl) 0;
  max-width: 75%;

  @include respond(sm, false) {
    max-width: 100%;
  }

  &__title {
    margin-bottom: var(--pf-v5-global--spacer--sm);
  }
}

.ptl-lab-component-item {
  &__action {
    min-width: 48px;

    &--no-margin {
      --pf-v5-c-data-list__item-action--MarginLeft: 0;
    }
  }
}
