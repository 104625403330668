.exam-view {
  &__loading {
    margin-top: var(--pf-v5-global--spacer--4xl);
  }

  &__alert {
    margin-bottom: var(--pf-v5-global--spacer--xl);
  }
}

.exam-not-found-message {
  padding: 25px 0;
  text-align: center;
}
