@import '../../../styles/variables';
@import '../../../styles/animations';

.bookmark-button {
  display: inline-flex;
  z-index: 1;
  right: 0;
  margin-top: 6px;
  color: $brand-primary;

  &:hover {
    color: $brand-primary-hover;
  }

  .favorite-icon:focus {
    outline: none;
  }

  .favorite-icon {
    font-size: 21px;
    margin-right: 5px;
  }

  &>.glyphicon-star {
    animation: grow 300ms ease-in;
  }

}

.section-title-wrapper:after {
  content: '';
  display: table;
}