@import '../../../../styles/variables';
// @import "../../../../styles/mixins/flexbox";

.lab-info-panel {
  .glyphicon {
    margin-right: 5px;
  }

  .orientation-icon,
  .dynamic-launch-icon {
    margin-right: 5px;
    position: relative;
    top: 5px;
  }

  .filter-panel-toggle {
    cursor: pointer;

    .filter-panel-heading {
      background-color: #f5f5f5;

      .filter-panel-heading-title {
        display: flex;

        .filter-panel-heading-icon {
          display: flex;
          margin-left: auto;

          svg {
            width: 15px;
          }
        }
      }
    }
  }

  .filter-panel-body {
    padding: 20px !important;

    .filter-panel-body {
      ul,
      ol {
        padding-left: 20px;
      }

      .margin-bottom {
        margin-bottom: 10px;
      }
    }
  }

  .filter-panel-footer {
    .lab-video-btn-wrapper {
      display: flex;

      button {
        margin-left: auto;
      }
    }

    .footer-btn-wrapper {
      display: flex;
      align-items: baseline;
      place-content: flex-end;

      .lab-ssh-btn-lbl-error {
        color: $note-warning-color;
      }

      button {
        margin-left: 10px;
      }

      .disabled-ssh-btn {
        color: $color-ws-cancel-dark !important;
        background-color: $color-ws-cancel-light !important;
        border-color: $color-ws-cancel-light !important;
      }

      .disabled-ssh-btn:hover,
      .disabled-ssh-btn:active,
      .disabled-ssh-btn:focus,
      .disabled-ssh-btn.active {
        outline: none;
      }
    }

    .lab-orient-video-btn-wrapper {
      display: flex;

      button {
        margin-left: auto;
      }

      .disabled-orient-btn {
        visibility: hidden !important;
      }

      .disabled-orient-btn:hover,
      .disabled-orient-btn:active,
      .disabled-orient-btn:focus,
      .disabled-orient-btn.active {
        outline: none;
      }
    }

    .dynamic-launch-footer {
      height: 40px;
    }
  }

  .dynamic-launch-widget {
    // text-align: center;

    .dynamic-launch {
      text-decoration: none;
      list-style-type: none;
      padding: 5px 0;
      margin: 0;
      display: flex;

      li {
        display: flex; /* Ensure each list item aligns its contents horizontally */
        align-items: center; /* Align contents vertically */
        padding: 5px;
        padding: 0px 30px 0px 0px;

        .disconnected-switch {
          margin: 0px;
        }
      }

      .react-selectize.root-node {
        font-family: inherit;
        width: 250px;

        .heat-template-single {
          width: 275px;
        }

        .option-wrapper {
          .simple-option.not-selectable {
            cursor: not-allowed;
          }
        }

        .react-selectize-control {
          -webkit-border-radius: 0;
          border-radius: 0;
          background-color: white !important;
          background-image: none !important;

          .react-selectize-placeholder {
            line-height: 35px;
            font-size: 14px;
          }
        }
      }
    }
  }
}
