@use 'sass:math';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.circle-indicator-container {
  display: flex;
  flex-wrap: wrap;

  .circle-indicator {
    background-color: white;
    border: 2px solid black;
    border-radius: 50%;
    width: $grid-distance * 3;
    height: $grid-distance * 3;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;

    @media (max-width: $screen-sm-min) {
      margin-bottom: math.div($grid-distance, 2);
    }
  }

  svg {
    width: $grid-distance * 3;
    height: $grid-distance * 3;

    @media (max-width: $screen-sm-min) {
      margin-bottom: math.div($grid-distance, 2);
    }
  }
}

.circle-indicator-container > * {
  margin-left: $grid-distance;

  &:first-child {
    margin-left: 0;
  }
}
