@import "../../../styles/variables";

.drawer-card-wrapper {
  min-height: 180px;
  display: flex;

  .hasError {
    background-color: #faeae8 !important;
  }

  &:last-child {
    margin-bottom: 20em;
  }

  .drawer-card {
    padding: 15px;
    border-bottom: 1px solid rgb(170, 170, 170);
    background-color: white;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: justify;

    &:hover {
      border-left: 4px solid $secondary-red-color;
    }

    .top-part,
    .bottom-part {
      font-weight: bold;
      display: flex;
      margin-bottom: 15px;
      justify-content: space-between;
    }

    .bottom-part {
      font-size: 15px;
    }

    .middle-part {
      width: 100%;
      margin-bottom: 15px;
      display: flex;
      text-align: left;
    }

    .buttons-part {
      display: flex;
      margin-top: auto;
      align-items: center;

      .delete-btn-wrap {
        margin-left: 15px;
        line-height: 0px;
      }

      .delete-btn-wrap:before,
      .archive-btn-wrap:before {
        content: "";
        display: inline-block;
        height: 100%;
        vertical-align: middle;
      }

      .archive-btn-wrap {
        line-height: 0px;

        svg {
          width: 22px;
          height: 22px;
          margin: 0 auto;
        }
      }

      .action-btn-wrap {
        margin-left: auto;
      }

      .bottom-part {
        margin-bottom: 15px;
      }

      .drawer-card-action-btn {
        background-color: $secondary-red-color;
        border-color: $secondary-red-color;
      }

      .error-message-container {
        display: flex;
        align-content: center;
        margin-left: 8px;

        .error-message {
          font-size: 14px;
        }
      }
    }

    .selected {
      border-left: 4px solid $secondary-red-color;
    }

    .delete-btn-wrap:focus,
    .archive-btn-wrap:focus,
    button:focus {
      &:focus {
        outline: 1px solid;
      }
    }
  }

  .isArchived {
    background-color: #f3f3f3;
    color: #939393;
  }
}

#tooltip {
  z-index: 2000;
}
