.lab-info-icons-container {
  display: flex;
  align-items: center;

  .btn-link {
    & > svg:hover {
      opacity: 0.8;
    }
  }
}

.auto-timer-btn {
  padding: var(--pf-v5-global--spacer--sm);
}
