$badge-image-width: 200px;
$badge-image-height: 200px;


.achievement-list {
  display: flex;
  flex-direction: column;
  gap: var(--pf-v5-global--spacer--lg);

  &__item {
    display: flex;
    flex-direction: row;
    align-items: stretch;
    gap: var(--pf-v5-global--spacer--md);
  }

  &__info {
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    color: black;
    flex: 1;
    padding: 8px 0;
  }

  &__title {
    font-size: 26px;
    font-weight: 900;
    margin: 0;
  }

  &__date {
    font-size: 16px;
    font-weight: 900;
    margin-top: var(--pf-v5-global--spacer--md);
    margin: 0;
  }

  &__link {
    display: flex;
    cursor: pointer;

    &:hover {
      & > img {
        transition : filter 0.2s linear;
        filter: brightness(80%);
      }
    }
  }

  &__card {
    // flex: 1;
  }

  &__card-skeleton {
    position: relative;
    box-sizing: border-box;
    width: $badge-image-width;
    height: $badge-image-height;
    opacity: 0.7;
  }

  &__card-skeleton-msg {
    position: absolute;
    bottom: 110px;
    left: 24px;
  }

  &__img {
    width: $badge-image-width;
    height: $badge-image-height;
    pointer-events: none;
  }
}
