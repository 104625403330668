@import '../../styles//animations';

.tech-overview {
  padding-top: var(--pf-v5-global--spacer--xl);
  padding-bottom: var(--pf-v5-global--spacer--xl);

  &__actions {
    display: flex;
    justify-content: flex-end;
    padding-top: var(--pf-v5-global--spacer--sm);

    > * {
      margin-left: var(--pf-v5-global--spacer--xl);
    }
  }

  &__player {
    margin-top: var(--pf-v5-global--spacer--md);
    animation: fade-in 400ms ease-in;
    animation-fill-mode: backwards;
    animation-delay: 200ms;
  }

  &__title {
    animation: fade-in 400ms ease-in;
    animation-fill-mode: backwards;
  }

  &__skeleton-container {
    position: relative;
    width: 100%;
    aspect-ratio: 16/9;
    padding-top: var(--pf-v5-global--spacer--md);
  }

  &__skeleton-icon {
    position: absolute;
    z-index: 3;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    opacity: 0.1;
    --pf-v5-c-skeleton--after--AnimationDuration: 1.4s;
  }
}
