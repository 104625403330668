@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

.select-organization-team {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  margin-left: 15px;

  @media (max-width: $screen-sm-max) {
    min-height: 0px;
  }

  .panel-heading {
    display: flex;
    justify-content: center;
  }

  .panel-body {
    padding: 30px;

    .form-sub-title {
      text-align: center;
    }
  }

  .panel-footer {
    display: flex;
    justify-content: center;
    margin-top: auto;

    .blue-btn {
      background-color: #06c;
      color: white;
    }
  }

  svg {
    height: 100px;
    width: 150px;
    color: red;
  }
}
