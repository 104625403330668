@import '../../styles/variables';

.my-progress-widget {
  border: none !important;

  .panel-heading {
    background-color: $color-course !important;
    border-radius: 0;

    .panel-title {
      color: white;
    }
  }

  & > .panel-collapse > .panel-body {
    padding: $form-padding 0;
  }

  .panel-footer {
    text-align: center;
    cursor: pointer;
    background-color: $gray-lighter;
  }

  .my-progress-widget-expand {
    display: block;
    float: right;

    svg {
      fill: white;
      width: $form-padding;
    }
  }

  .no-progress-msg {
    text-align: center;
  }
}
