@import '../../../styles/_variables.scss';

.impersonating-user-banner {
  background-color: $color-primary;
  color: $color-white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding: 10px;

  span.isvg.loaded {
    margin-right: 10px;
  }

  button {
    margin-left: 10px;
  }
}
