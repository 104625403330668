///
/// @file Course Catalog store
/// @author Joshua Jack <jjack@redhat.com>
///

@import '../../styles/variables';
@import '../../styles/animations';
@import '../../components/Catalog/Filters/styles';
@import "../../styles/mixins/mediaqueries";

.catalog {
  &__loading-container {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__content {
    display: flex;
    flex-direction: row;

    @include respond(lg, false) {
      flex-direction: column;
    }
  }

  &__list-container {
    display: inline-flex;
    flex-direction: column;
    flex: 1;
    align-items: center;
    transition: 0.2s;
  }

  &__list {
    width: 100%;
  }

  &__list {
    & > * {
      animation: fade-in 200ms ease-in-out;
    }
  }

  &__search {
    margin-bottom: 12px;
  }
}
