.manager-enabled {
  color: green;
}

.manager-disabled {
  color: red;
}

.delete-key {
  color: red;
}

.uncheckedIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 10px;
  color: white;
  padding-right: 12px;
}

.checkedIcon {
  display: flex;
  justify-content: left;
  align-items: center;
  height: 100%;
  font-size: 10px;
  color: white;
  padding-left: 8px;
}

.manager-search-bar {
  margin-right: 950px;
}

.btn-transparent {
  background-color: transparent;
  border-color: transparent;
}
