.admin-dashboard-wrapper {
  margin-bottom: 100px;

  .svg-holder span:focus {
    outline: none;
    box-shadow: none;
  }

  .dashboard-list-title {
    grid-column-start: 2;
    line-height: 38px;
    font-size: 18px;
    font-weight: larger;
    padding: 0;
    margin: 0;
  }

  &__loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }
}

p.add-manager-label {
  font-weight: bold;
}
