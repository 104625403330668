@import '../../styles/variables';

@mixin filter-heading-title($color: $gray-dark) {
  color: $color;
  font-size: 1.2em;
  font-weight: 200;
  text-transform: uppercase;
}

.premium-filters {
  display: inline-block;
  vertical-align: top;
  transition: 0.2s;
  width: 100%;
  margin-right: $form-padding;

  .filter-group-heading {
    @include filter-heading-title(white);
    background-color: $gray-dark;
    padding: $form-padding;
  }

  .filter-clear {
    background-color: $gray-lighter;
    font-weight: 700;
    text-align: center;
    padding: $form-padding;
    cursor: pointer;
  }

  #premium-filter-panel {
    overflow-x: hidden;
    border: 1px solid $gray-lighter;

    .panel {
      margin-top: 0;
      border: none;
      border-radius: 0;

      &:not(:last-child) {
        &:after {
          content: '';
          border-bottom: 1px solid $gray-lighter;
          margin-left: $form-padding;
          display: block;
        }
      }

      .filter-panel-toggle {
        .filter-panel-heading {
          cursor: pointer;
          display: flex;
          width: 100%;
          justify-content: space-between;

          .filter-panel-heading-title {
            @include filter-heading-title;
          }

          &:hover {
            .filter-panel-heading-title {
              color: $gray;
            }
          }

          .filter-panel-heading-icon {
            margin-left: auto;

            svg {
              width: 15px;
            }
          }
        }
      }

      .filter-control {
        margin-top: 0;
        width: 100%;

        label {
          width: 100%;
        }
      }

      .filter-control-wrapper {
        display: flex;
        width: 100%;
        justify-content: space-between;

        .filter-control-badge {
          margin-left: auto;
          color: $gray-light;
        }
      }

      &.early-access {
        .filter-panel-heading {
          background-color: $color-accent-blue;
        }

        .form-group {
          margin-bottom: 0;
        }

        .filter-control {
          margin-bottom: 0;

          label {
            @include filter-heading-title(white);
          }
        }

        .filter-control-wrapper {
          .filter-control-badge {
            color: white;
          }
        }

        &:not(:last-child) {
          &:after {
            content: '';
            border-bottom: none;
          }
        }
      }
    }

    .filter-panel-body {
      padding-top: 0;

      .form-group:last-child {
        margin-bottom: 0;
      }
    }

    .date-control-wrapper {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      .date-btn {
        background-color: #08c0fc;
        color: #ffffff;
        border: 1px solid #08c0fc;
        border-radius: 0rem 0.25rem 0.25rem 0rem;
        min-width: 50px;
        padding: 0;
      }

      .cancel-btn {
        background-color: #e9ecef;
        color: grey;
        border-radius: 0;
        border-top: 1px solid #ced4da;
        border-bottom: 1px solid #ced4da;
        border-left: 0;
        border-right: 0;
        height: 99%;
        min-width: 50px;
      }

      .date-field {
        border-radius: 0.25rem 0rem 0rem 0.25rem;
        border-right: 0;
        background-color: #ffffff;
      }
    }
  }
}

.calendar-modal-dialog {
  width: fit-content !important;

  .flatpickr-input {
    width: 100%;
    text-align: center;
    color: rgb(101, 101, 101);
    border: 0;
  }

  .calendar-buttons {
    font: inherit;
    margin-bottom: 15px;
  }

  .DayPicker {
    font-size: inherit;
  }

  .Selectable
  .DayPicker-Day--selected
  :not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: #4a90e2;
  }

  .Selectable .DayPicker-Day {
    border-radius: 0 !important;
  }

  .Selectable .DayPicker-Day--start {
    border-top-left-radius: 50% !important;
    border-bottom-left-radius: 50% !important;
  }

  .Selectable .DayPicker-Day--end {
    border-top-right-radius: 50% !important;
    border-bottom-right-radius: 50% !important;
  }

  .modal-content,
  .modal-body,
  .modal-header {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .calendar-modal-title {
    color: rgb(101, 101, 101);
  }

  .close-btn {
    background-color: #08c0fc;
    color: #ffffff;
    border: 1px solid #08c0fc;
    border-radius: 0.25rem 0.25rem;
  }

  .clear-btn {
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    border-radius: 0.25rem 0.25rem;
    margin-right: 15px;
    margin-left: 15px;
  }

  .date-picker-wrapper {
    display: flex;
    justify-content: center;

    // @media (min-width: 576px) {
    //   .calendar-form {
    //     min-width: 570px;
    //   }
    // }
  }

  .time-picker-wrapper {
    display: inline;
    justify-content: center;

    .flatpickr-input {
      display: none;
    }
  }
}
