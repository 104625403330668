@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

#add-manager-button {
  padding-top: 15px;
  float: right;
}

.add-manager-form-label {
  font-weight: normal;
}