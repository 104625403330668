@import '../../../styles/variables';

.my-classes-widget {
  border: none !important;

  .panel-heading {
    background-color: $color-class !important;
    border-radius: 0;

    .panel-title {
      color: white;
    }
  }

  & > .panel-collapse > .panel-body {
    padding: $form-padding 0;
  }

  .panel-footer {
    text-align: center;
    cursor: pointer;
    background-color: $gray-lighter;
  }

  .my-classes-widget-expand {
    display: block;
    float: right;

    svg {
      fill: white;
      width: $form-padding;
      height: 15px;
    }
  }

  .my-classes-widget-premvt-show {
    display: block;
    float: right;
    margin-right: 3rem;

    svg {
      width: 25px;
      height: 25px;
    }
  }

}
