@import '../../../styles/variables';
@import "../../../styles/mixins/flexbox";

.expiring-subscriptions-report-wrapper {
  margin-top: 20px;

  .input-bar-header {
    margin: 0;
    background-color: $gray-lighter;
    padding: 5px;
    padding-left: 10px;
  }

  .loading {
    min-width: 149px;
  }

  #expiring-subscription-inputs {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include align-content(flex-start);
    -ms-flex-pack: justify;
    width: 100%;
    min-height: 30px;
    padding: 10px;
    background-color: $gray-lightest;
    font-size: 0.8em;
    margin-bottom: 20px;

    & > * {
      @include flex(0);
      @include align-self(auto);
      margin: 0 5px;
      white-space: nowrap;
    }

    .rdt {
      input {
        width: auto;
        height: 40px;
      }
    }

    .submit-btn-container {
      @include align-self(flex-end);
      flex: none;
      display: table;
    }
  }
}