@import '../../../styles/mixins/mediaqueries';
@import '../../../styles/variables';

.free-trial-offering {
  --pf-v5-global--primary-color--100: #{$color-primary};
  --pf-v5-global--primary-color--200: #{$color-primary-hover};

  &__title {
    color: var(--pf-v5-global--primary-color--100);
    font-size: var(--pf-v5-global--FontSize--2xl);
    margin-bottom: var(--pf-v5-global--spacer--lg);
  }

  &__row {
    display: flex;
    flex-direction: row;
    gap: var(--pf-v5-global--spacer--md);
    flex-wrap: wrap;

    @include respond(md, false) {
      flex-direction: column;
    }
  }

  &__card {
    display: inline-flex;
    flex-direction: column;
    gap: var(--pf-v5-global--spacer--md);
    background-color: var(--pf-v5-global--palette--black-150);
    align-items: flex-start;
    justify-content: flex-start;
    padding: var(--pf-v5-global--spacer--xl);
    flex: 1;
  }

  &__card-title {
    font-size: var(--pf-v5-global--FontSize--xl);
    margin-top: 0;
    font-weight: var(--pf-v5-global--FontWeight--bold);
  }

  &__video {
    min-width: 30%;
    flex: 1;
    max-width: 400px;
    margin-left: var(--pf-v5-global--spacer--2xl);

    @include respond(md, false) {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: var(--pf-v5-global--spacer--md);
      max-width: 100%;
      width: 100%;
    }
  }
}
