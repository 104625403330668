@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

$class-card-icon-width: 70px;

.class-card {
  color: $gray-base;

  .label {
    font-size: 0.8em;
    margin-right: 5px;
  }

  .time {
    font-size: 0.8em;
  }

  &.panel {
    border-radius: 0;
  }

  .panel-body {
    display: flex;
  }

  .class-start-time,
  .class-end-time {
    padding-left: $form-padding;
    border-left: 1px solid gray;
  }

  .class-content-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .class-icon {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;  
    min-width: $class-card-icon-width;
    height: $class-card-icon-width;
    vertical-align: top;
    border-radius: 50%;
    background-color: $color-class;
    margin: 5px $form-padding 0 0;
    overflow: hidden;
    position: relative;

    >.isvg.loaded {

      /* centering the modality icon */
      height: 100%;
      position: relative;
      display: block;

      svg {
        max-height: 65%;
        position: absolute;
        top: 15%;
      }
    }
  }

  .class-content {
    display: inline-block;
    width: 100%;
  }

  .showText {
    display: inline-block;
  }

  .hideText {
    display: none;
  }

  .class-meta {
    display: flex;
    align-items: center;

    &> :not(:last-child) {
      margin-right: $form-padding;
    }
  }

  .class-title {
    font-weight: 600;
    padding-bottom: $form-padding;
  }

  .class-view {
    padding: 0 $form-padding;
    font-weight: bold;
    font-size: 14px;

    svg {
      width: 20px;
    }
  }

  .class-actions {
    margin-left: auto;
    margin-right: 15px;
  }
}
