///
/// @file Flipping banner - a reusable animated banner component with dynamic props
/// @author Attila Farkas <afarkas@redhat.com>
///

@import '../../../styles/variables';

.flipping-banner-container {
  padding: 15px 15px 15px 0px;
  margin-bottom: 15px;
  margin-top: 15px;
  border-top: 1px solid #d2d3d5;
  border-right: 1px solid #d2d3d5;
  border-bottom: 1px solid #d2d3d5;
  border-left: 4px solid $color-primary;

  -webkit-animation: fadeInFromNone 0.8s ease-out;
  -moz-animation: fadeInFromNone 0.8s ease-out;
  -o-animation: fadeInFromNone 0.8s ease-out;
  animation: fadeInFromNone 0.8s ease-out;

  .flipping-description-banner {
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 118px;

    .flipping-banner-img {
      margin: 0 15px;
    }
  }
  @-webkit-keyframes fadeInFromNone {
    0% {
      display: none;
      transform: rotateX(180deg);
    }
  
    100% {
      display: block;
    }
  }
  
  @-moz-keyframes fadeInFromNone {
    0% {
      display: none;
      transform: rotateX(180deg);
    }
  
    100% {
      display: block;
    }
  }
  
  @-o-keyframes fadeInFromNone {
    0% {
      display: none;
      transform: rotateX(180deg);
    }
  
    100% {
      display: block;
    }
  }
  
  @keyframes fadeInFromNone {
    0% {
      display: none;
      transform: rotateX(180deg);
    }
  
    100% {
      display: block;
    }
  }
}

.premiumGeneralFeedbackBtn-wrapper {

  max-width: 162px;
  margin-left: auto;

  .premiumGeneralFeedbackBtn {
    background-color: #c00;
    color: white;
  }
} 
