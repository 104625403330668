@import '../../styles/variables';

#session-details-wrapper {
  .panel-default {
    padding: 0;

    .panel-heading {
      border-color: #ddd;
      height: 46px;
    }
  }

  .panel-heading {
    padding: 0;
    border-bottom: 1px solid transparent;
    border-top-right-radius: 0;
    border-top-left-radius: 0;

    .panel-title {
      a:hover,
      a:focus {
        text-decoration: none;
      }

      .session-dashboard-overview-table-head {
        td {
          padding-left: 15px;
        }

        .session-name {
          background-color: #007a87;
          border-color: #007a87;
          color: #fff;

          .course-title-wrapper {
            max-height: 128px;
            padding: 12px;
          }

          .course-expand {
            display: flex;
            justify-content: flex-end;
            padding-right: 15px;
            min-height: 46px;

            svg {
              width: 15px;
            }
          }
        }
      }
    }
  }

  .panel-body {
    padding: 0;

    .session-dashboard-overview-table {
      padding: 0;
      margin-bottom: 0;

      td {
        padding-left: 15px;
      }

      .progress-indicators {
        background-color: #007a87;
        vertical-align: middle;
      }
    }
  }

  .attend-info {
    margin-top: 10px;
    margin-bottom: 10px;
    padding-right: 10px;
    font-style: italic;
  }

  .free-tier-alert {
    margin-bottom: var(--pf-v5-global--spacer--sm);
  }
}
