.content-version-hint {
  margin-top: var(--pf-v5-global--spacer--sm);

  --pf-v5-c-hint--PaddingTop: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-hint--PaddingRight: var(--pf-v5-global--spacer--md);
  --pf-v5-c-hint--PaddingBottom: var(--pf-v5-global--spacer--sm);
  --pf-v5-c-hint--PaddingLeft: var(--pf-v5-global--spacer--md);

  &__body {
    gap: 26px;
    display: inline-flex;
    flex-wrap: wrap;
  }

  &__code {
    pre {
      border: none;
    }
  }

  &__label-group {
    margin-bottom: var(--pf-v5-global--spacer--sm);
  }
}
