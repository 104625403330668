.lab-controls-info {
  &-panel {
    min-width: 40em;
  }

  .lab-controls-info-btn {
    margin: 0;
    font-size: 1.2em;

    .glyphicon {
      margin: 0;
      top: 0;
    }
  }

  .panel-body {
    font-size: small;
    line-height: 1;
  }
}
