@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

.mgmt-csk-users-list {
  margin-bottom: 15px;

  .row-user-header {
    line-height: 20px;

    .column-user {
      cursor: pointer;

      span {
        font-weight: bold;
      }
    }

    @media (max-width: $screen-sm-max) {
      display: none !important;
    }
  }

  .row-user {
    display: flex;
    border-left: 0px solid #cccc;
    border-right: 0px solid #cccc;
    border-top: 0px solid #cccc;
    border-bottom: 1px solid #cccc;
    cursor: pointer;
    align-items: center;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    .column-user {
      white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
      white-space: -webkit-pre-wrap; /* Chrome & Safari */
      white-space: -pre-wrap; /* Opera 4-6 */
      white-space: -o-pre-wrap; /* Opera 7 */
      white-space: pre-wrap; /* CSS3 */
      word-wrap: break-word; /* Internet Explorer 5.5+ */
      word-break: break-all;
      white-space: normal;

      flex-basis: 100%;
      flex: 1;

      margin-right: auto;
      padding: 2px;

      .row-sm-header {
        display: none;

        font-weight: lighter;

        @media (max-width: $screen-sm-max) {
          display: inline;
        }
      }
    }

    .check-column {
      flex: 0.05;
    }

    .org-progress {
      .progress-bar {
        background-color: #ddd !important;
        color: black;
      }
    }

    .progress {
      margin: 0;

      border-radius: 0;
      height: 20px;

      .progress-bar {
        background-color: red;
        text-align: right;
        padding-right: 0px;
        padding-left: 0px;
        min-width: 21px;

        &[aria-valuenow='0'] {
          color: $gray-light;
          background: transparent;
          box-shadow: none;
        }
      }
    }
  }
}
