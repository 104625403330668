@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';
@import '../../styles/animations';

.free-trial-registration {
  --pf-v5-global--primary-color--100: #{$color-primary};
  --pf-v5-global--primary-color--200: #{$color-primary-hover};

  display: flex;
  flex-direction: row;
  padding-top: var(--pf-v5-global--spacer--md);
  padding-bottom: var(--pf-v5-global--spacer--4xl);

  animation: fade-in 150ms ease-in;
  animation-fill-mode: backwards;

  @include respond(md, false) {
    flex-direction: column-reverse;
    align-items: center;
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__video {
    min-width: 30%;
    flex: 1;
    max-width: 400px;
    margin-left: var(--pf-v5-global--spacer--2xl);
    margin-top: var(--pf-v5-global--spacer--4xl);

    @include respond(md, false) {
      margin-left: 0;
      margin-top: 0;
      margin-bottom: var(--pf-v5-global--spacer--md);
      max-width: 100%;
      width: 100%;
    }
  }

  &__loading-state {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
  }

  &__form-marketing {
    padding-top: var(--pf-v5-global--spacer--sm);
  }

  &__mkt-checkbox {
    margin-top: var(--pf-v5-global--spacer--md);

    & > input {
      // workaround for boostrap globals:
      margin-top: 0 !important;
    }
  }

  &__form-consent {
    padding-top: var(--pf-v5-global--spacer--md);
    border-top: 1px solid var(--pf-v5-global--BorderColor--100);
  }

  &__form-captcha {
    border-top: 1px solid var(--pf-v5-global--BorderColor--100);
    border-bottom: 1px solid var(--pf-v5-global--BorderColor--100);
    padding-top: var(--pf-v5-global--spacer--lg);
    padding-bottom: var(--pf-v5-global--spacer--lg);
  }

  &__form-error {
    margin-top: var(--pf-v5-global--spacer--md);
  }
}
