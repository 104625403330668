

@mixin respond($breakpoint, $mobile-first: true) {
  $media-query-type: if($mobile-first,'min-width','max-width');

  @if $breakpoint == sm {
    @media only screen and (#{$media-query-type}: 576px) {
      @content;
    }
  }

  @if $breakpoint == md {
    @media only screen and (#{$media-query-type}: 768px) {
      @content;
    }
  }

  @if $breakpoint == lg {
    @media only screen and (#{$media-query-type}: 992px) {
      @content;
    }
  }

  @if $breakpoint == xl {
    @media only screen and (#{$media-query-type}: 1200px) {
      @content;
    }
  }

  @if $breakpoint == xxl {
    @media only screen and (#{$media-query-type}: 1450px) {
      @content;
    }
  }
}
