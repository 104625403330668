@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.dashboard-wrapper {
  margin-bottom: 100px;

  .svg-holder span:focus {
    outline: none;
    box-shadow: none;
  }
}

.dashboard-nav {
  height: 60px;
  display: flex;
  justify-content: center;
  margin-top: 15px;

  a {
    height: 100%;
    text-decoration: none;
    color: black;

    &:not(:last-child) {
      margin-right: $form-padding * 2;
    }

    .nav-link {
      position: relative;

      &:after {
        content: '';
        height: 4px;
        position: absolute;
        width: 100%;
        background-color: #ddd;
        bottom: -9px;
        left: 0;
      }
    }

    &.active,
    &:hover {
      .nav-link {
        &:after {
          background-color: $color-primary;
          width: 100%;
        }
      }
    }
  }
}
