.resequencing {
  padding: 10px 10px 5px;
  margin: 0;

  li {
    list-style-type: none;
    padding: 0 40px;
    margin: 10px 10px 15px;

    .resequencing_label {
      background-color: #337ab7;
      border-color: #2e6da4;
      color: #fff;
      font-weight: 700;
      line-height: 1;
      text-align: center;
      border-radius: .25em;
      padding: .2em .6em .3em;
      display: inline;
      cursor: move;

      p {
        display: inline;
      }
    }

    &.correct {
      .resequencing_label {
        background-color: green;
      }
    }

    &.incorrect {
      .resequencing_label {
        background-color: red;
        text-decoration: line-through;
      }

    }
  }
}
