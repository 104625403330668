@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.session-dashb-responsive {

  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;

  .session-dashboard {
    .title-line {
      display: flex;
      width: 100%;

      h3.next-timer {
        margin-left: auto;
      }

      h3.starts-soon {
        color: #870000;
      }
    }
  }

  #session-details-wrapper {
    min-width: 360px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: $screen-sm-min) { 
    width: 750px; 
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: $screen-md-min) { 
    width: 970px; 
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: $screen-lg-min) { 
    width: 1170px;
  }

  // Extra small devices (portrait phones, less than 576px)
  @media (max-width: $screen-xs-max) { 
    padding-left: 0;
    padding-right: 0;
    width: 100%;

    .btn {
      height: 58px;
      font-size: large;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
