@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

$course-card-icon-width: 40px;

.course-card {
  color: $gray-base;
  cursor: pointer;

  &.panel {
    border-radius: 4px;
  }

  .panel-body {
    padding-bottom: 0;
    display: flex;
  }

  .course-content-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .course-icon {
    display: inline-block;
    min-width: $course-card-icon-width;
    height: $course-card-icon-width;
    vertical-align: top;
    margin: 5px $form-padding 0 0;
    overflow: hidden;
    position: relative;

    .content-locked {
      background-color: rgba($gray-darker, 0.8);
      width: 100%;
      height: 45%;
      position: absolute;
      bottom: 0;
      left: 0;

      svg {
        max-height: 20px;
        margin-top: 4px;
        fill: inherit;
      }
    }

    > .isvg.loaded {

      /* centering the modality icon */
      height: 100%;
      position: relative;
      display: block;

      svg {
        max-width: 75%;
        max-height: 80%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .course-content {
    display: inline-block;
    width: 100%;
  }

  .badge-icon-wrapper {
    display: flex;
    flex-direction: row;
  }

  .slide {
    height: 35px;
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;
    line-height: 35px;
    padding-left: 10px;
    padding-right: 10px;
    z-index: 1;
  }

  .ea-slide {
    min-width: 112px;
    background-color: $color-accent-blue;
    color: #ffffff;
  }

  .ra-slide {
    min-width: 146px;
    background-color: #ccc5b4;
    color: #000000;
  }

  .showText {
    display: inline-block;

    p {
      margin: 0;
    }
  }

  .hideText {
    display: none;
  }

  .course-meta {
    display: flex;
    align-items: center;

    & > :not(:last-child) {
      margin-right: $form-padding;
    }

    .exam-status {
      color: red;
    }
  }

  .course-collateral {
    margin-top: $form-padding;
    border-top: 1px solid $color-secondary;
  }

  .course-collateral-description {
    margin-top: $form-padding;
  }

  .course-title {
    font-weight: 600;
    padding-bottom: $form-padding;
  }

  .course-view {
    padding: 0 $form-padding;
    font-weight: bold;
    font-size: 14px;

    svg {
      width: 20px;
    }
  }

  .course-actions {
    margin-left: auto;
    margin-right: 15px;
  }

  .course-actions-disabled {
    pointer-events: none;
  }

  .caret-wrapper {
    height: 50px;
    float: right;
  }

  .course-expand {
    position: relative;
    top: 50%;
    transform: translateY(50%);

    svg {
      width: 15px;
    }
  }

  &.exam {
    .course-icon,
    .progress-bar {
      background-color: $color-exam;
    }
  }

  &.prelim-exam {
    .course-icon,
    .progress-bar {
      background-color: $color-prelim-exam;
    }
  }

  &.remote-exam {
    .course-icon,
    .progress-bar {
      background-color: $color-remote-exam;
    }
  }

  &.expert_seminar {
    .course-icon,
    .progress-bar {
      background-color: $color-expert-seminar;
    }
  }

  .progress-version-container {
    display: flex;
    align-items: flex-end;

    #progress-version-selector {
      height: 20px;
      padding: 0 5px 0 10px;
      border-left: 0;
      border-right: 0;
      border-bottom: 0;
      font-size: 0.8em;
      font-weight: normal;
      text-transform: lowercase;
      background-color: $progress-bg;
    }

    .progress-container {
      flex-grow: 1;
    }
  }
}

.exam-card {
  .course-actions-disabled {
    pointer-events: none;
  }
}

.card-with-ordering {
  display: flex;
}

.card-body-with-ordering {
  flex-grow: 1;

  .panel {
    margin-bottom: 0px !important;
    border: none;
  }
}

.panel-with-ordering {
  margin-bottom: 0px;
  border: none;
  flex-grow: 1;
}
