.enterprise-trial-stats {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: center;
  gap: var(--pf-v5-global--spacer--md);
  margin-top: var(--pf-v5-global--spacer--md);

  &__item {
    flex: 1;
  }

  &__item-title {
    color: black;
    font-weight: 700;
    font-size: 18px;
  }

  &__item-icon {
    margin-right: var(--pf-v5-global--spacer--md);
  }

  &__item-stat-body {
    flex-grow: 0 !important;
  }

  &__item-stat {
    text-align: center;
    color: black;
    font-weight: 700;
    font-size: 60px;
    line-height: 60px;
  }

  &__item-description {
    margin-top: var(--pf-v5-global--spacer--lg);
  }

  &__item-alert {
    border: none !important;

    // workaround against boostrap global styles
    & > * {
      font-size: 16px !important;
      line-height: 16px !important;
    }
  }
}
