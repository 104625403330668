@use 'sass:math';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.custom-skill-path-form-container {
  color: $gray-base;
  margin-bottom: $grid-distance;

  .panel-custom-skill-path-form {
    display: flex;
    flex-direction: column;

    .learning-path-input {
      padding: 15px;
      padding-right: 1px;
      border: 0px solid #a5a5a5;

      .icon {
        width: 45px;
        min-width: 45px;
        height: 45px;
        min-height: 45px;

        svg {
          width: 40px;
          height: 40px;
        }
      }

      .learning-path-elements {
        padding-right: 15px;

        .vertical-timeline-element--work {
          background: 'rgb(249, 249, 249)';
          color: '#fff';
        }

        .form-group {
          border-bottom: 0px !important;
          margin-bottom: 0px;
        }

        .course-card {
          .body {
            margin: 0 10px;
            padding-left: 5px;
          }
        }
      }

      .search-offering-input {
        padding: 15px;

        .first {
          padding-top: 15px;
        }
      }

      .display-flex {
        display: flex;
      }

      .cskp-form {
        .align-right {
          text-align: right;
        }

        .form-group {
          border-bottom: 1px solid #a5a5a5;
        }

        .clp-title-input-group {
          .input-group-prepend {
            margin-right: 5px;
          }

          .custom-text {
            font-weight: 200;
          }
        }

        .padding-right {
          padding-right: 15px;
        }

        .validation-message {
          font-style: italic;
          font-size: small;

          .error {
            color: red;
          }
        }
      }

      .vertical-timeline::before {
        background: #a5a5a5;
        margin-left: -68px;
        margin-top: 40px;

        @media (max-width: $screen-sm-max) {
          margin-left: -60px;
        }
      }

      .vertical-timeline {
        width: 100%;

        .vertical-timeline-element {
          margin: 0.1em 0 0;

          .vertical-timeline-element-content {
            box-shadow: none;
            padding: 0;
            margin-left: 0px;

            &::before {
              border: none;
            }
          }

          .vertical-timeline-element-icon {
            margin-left: -58px;
            top: 20px;
            width: 20px;
            height: 20px;
            background-color: white;

            @media (max-width: $screen-sm-max) {
              margin-left: -50px;
            }
          }
        }
      }
    }
  }

  .csk-panel-footer {
    cursor: default !important;
    padding: 0 $grid-distance $grid-distance;
    border-bottom-right-radius: 0;

    .footer-elements-container {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      @media (max-width: $screen-sm-max) {
        flex-direction: row;
      }
    }

    .date-picker-container {
      justify-content: flex-start;
      display: flex;
      flex: 1;
      align-items: center;
    }

    .add-users-container {
      justify-content: flex-start;
      display: flex;
      flex: 1;
      align-items: center;

      .add-users-input-group {
        width: 150px;

        .form-control {
          background-color: white;
        }

        .add-users-text {
          background: transparent;
          border: 0px;

          &--invalid {
            color: red;
            font-style: italic;
          }

          @media (max-width: $screen-sm-max) {
            display: none;
          }
        }

        .add-users-btn-group {
          background-color: #08c0fc;
          border: 1px solid #08c0fc;

          .add-users-btn {
            background-color: #08c0fc;
            color: #ffffff;
            border: 1px solid #08c0fc;

            border-radius: 0rem 0.25rem 0.25rem 0rem;
            min-width: 20px;
            padding: 0;

            svg {
              width: 30px;
              height: 24px;
            }
          }
        }
      }
    }

    .actions-container {
      display: flex;
    }

    .actions-container>* {
      margin-left: math.div($grid-distance, 2);
      margin-right: math.div($grid-distance, 2);

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .csk-panel-header {
    display: flex;

    margin-left: 70px;
    justify-content: space-between;

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }

    span.header {
      padding: 10px;
    }
  }
}