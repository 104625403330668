@mixin icon($icon) {
  background: url('#{$img-path}/icons/#{$icon}.svg') no-repeat 0 0;
  display: inline-block;
  color: black;
  width: 100%;
  height: 100%;
}

.icon-modality-course,
.icon-modality-partner {
  @include icon('modality-course');
}

.icon-modality-ilt,
.icon-modality-os {
  @include icon('modality-ilt');
}

.icon-modality-vt {
  @include icon('modality-vt');
}

.icon-modality-vc,
.icon-modality-video_classroom {
  @include icon('modality-vc');
}
