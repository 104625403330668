.alert-preview-col {
  min-width: 20vw;
  width: 30vw;
}

.alert-location-col {
  width: 10vw;
}

.alert-subscriptions-col {
  width: 10vw;
}

.alert-actions-col {
  width: 12vw;
}