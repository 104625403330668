@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';
@import '../../../styles/mixins/flexbox';

@mixin tableHeader($bg-color: white) {
  color: var(--pf-v5-global--palette--black-900);
  font-size: var(--pf-v5-global--FontSize--xl);
  text-transform: uppercase;
  background-color: $bg-color;
  padding: var(--pf-v5-global--spacer--md) calc(var(--pf-v5-global--spacer--md) * 2);
  border-bottom: none;
}

.subscription-comparison-table {
  .learn-more {
    text-align: center;
  }

  .list-group, .list-group-item-placeholder {
    color: var(--pf-v5-global--palette--black-700);
    font-size: var(--pf-v5-global--FontSize--lg);
    padding: var(--pf-v5-global--spacer--md);
    margin-bottom: 0;

    .list-group-item {
      display: table;
      width: 100%;
      border-left: none;
      border-right: none;

      &:first-child {
        border-top: none;
      }

      & > span {
        display: table-cell;
        padding: var(--pf-v5-global--spacer--md);
      }

      .list-group-item-icon {
        color: var(--pf-v5-global--info-color--100);
        text-align: right;
      }
    }
  }

  .list-group-item-placeholder {
    border: none;
  }

  .subscription-comparison-table-heading {
    margin-bottom: calc(var(--pf-v5-global--spacer--md) * 3);
  }

  table {
    height: 100%;

    tr {
      th {
        text-align: center;
        text-transform: uppercase;
        border-bottom: none;
      }

      td {
        position: relative;
        border-top: none;
        padding-bottom: var(--pf-v5-global--spacer--md) !important;
      }
    }
  }

  .tier1 {
    table {
      thead th {
        @include tableHeader;
      }
    }
  }

  .tier2 {
    margin-bottom: var(--pf-v5-global--spacer--md);

    table {
      thead th {
        @include tableHeader(var(--pf-v5-global--palette--black-200));
      }
    }
  }

  .tier1, .tier2 {
    table {
      box-shadow: var(--pf-v5-global--BoxShadow--sm);
    }
  }

  .tier3 {
    margin-bottom: var(--pf-v5-global--spacer--md);

    table {
      box-shadow: var(--pf-v5-global--BoxShadow--lg);

      thead th {
        @include tableHeader(#007A87);
        color: white;
      }
    }
  }
}
