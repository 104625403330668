@import '~noty/src/noty.scss';
@import '~noty/src/themes/metroui.scss';
@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';

.ebook-download {
  .ebook-download-btn {

    background: none !important;
    box-shadow: none !important;

    &[aria-expanded="true"] {
      background: #e6e6e6 !important;
      color: #151515 !important;
    }

    @include respond(md, false) {
      width: 100%;
    }

    & svg {
      height: 0.75em;
    }
  }

  .ebook-download-btn {
    [class^="Alert-body-"] {
      font-size: 0.45em
    }

    width: 100%;
    font-size: 100%;
    font-weight: normal;
    text-transform: none;

    button {
      width: 100%;
      justify-content: space-evenly;

      .pf-v5-c-dropdown__toggle-icon {
        margin: 0;
      }
    }


    .dropdown-menu {
      z-index: 2000;
      width: 100%;
    }

    #ebook-download-icon {
      pointer-events: all;
    }

    .tandc-checkbox {
      color: #000000;
    }
  }

  .btn-default {
    border-color: #151515;
  }

  .btn-group {
    @include respond(md, false) {
      width: 100%;
      margin-bottom: 0.625rem;
    }
  }
}
