.collateral-link-chapter {
  .objectives-title {
    padding-top: 5px;
    padding-left: 15px;
  }

  .objectives-list {
    margin-left: 30px;
  }
}
