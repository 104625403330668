///
/// @file Frequently Asked Question View Styling
/// @author Attila Farkas <afarkas@redhat.com>
///
@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../styles/variables';


.faq-grid {
  svg {
    width: 20px;
    float: right;
  }
}

.faq-wrapper {
  .panel-group {
    max-width: 750px;
    margin: 0 auto;
  }

  .panel-group .panel {
    margin-bottom: 15px;
  }

  .red-title {
    color: red;
    margin-top: 20px;
    margin-bottom: 15px;
  }

  .text-wrapper {
    max-width: 750px;
    margin: 0 auto;
  }

  .toc-list {
    padding: 0;
    white-space: pre-wrap;
  }

  .toc-list li div {
    display: table-cell;
  }

  // TODO: not sure what this is for, potentially remove this?
  // assignee: afarkas@redhat.com
  // .toc-list li div span {
  //   display: table-cell;
  //   width: 1px;
  //   background-color: green;
  // }

  .toc-list li div a {
    margin-left: 5px;
  }

  .toc-list li div span {
    width: 40px;
    color: black;
  }

  .toc-list li div span,
  .toc-list li div a {
    display: table-cell;
  }

  .toc-list .toc-faq-question-link {
    cursor: pointer;
  }

  .toc {
    list-style: none;
  }

  .toc-faq-title {
    margin-bottom: 15px;
  }

  .toc-faq-title:not(:first-child) {
    margin-top: 15px;
  }
}
