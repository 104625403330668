@import '~react-placeholder/lib/reactPlaceholder.css';

.login {
  .login-page-alerts {
    margin-top: 20px;
  }

  .course-banner {
    padding: 30px 0;
    background-color: #ededed;
    margin-bottom: 15px;

    .container {
      display: table;
    }

    .welcome {
      margin-top: 10px;
    }

    .course-modality {
      background-color: #c3c4c6;
      border-radius: 50%;
      width: 100px;
      height: 100px;
      text-align: center;
      min-width: 100px;
      font-size: 3em;

      &.icon-modality-vt {
        &:before {
          margin-left: -10px;
        }
      }
    }

    .course-title {
      display: table-cell;
      width: 100%;
      padding-left: 30px;
      vertical-align: middle;
    }
  }

  .login-bottom {
    .equal {
      display: flex;
      display: -webkit-flex;
      flex-wrap: wrap;
    }

    .card-white {
      height: 100%;
    }
  }
}
