$progress-map-bar: lighten($color-primary, 25%);
$progress-map-bar-hover: lighten($color-primary, 10%);
$bg-active: $color-primary;
$bg-active-hover: $color-primary-hover;
$visited: $color-primary;
$visited-hover: $link-hover-color;
$disabled: lighten($text-color, 25%);
$disabled-hover: lighten($text-color, 10%);

#{$base_selector} {
  .progress-map {
    @include flexbox();
    @include flex-direction(row);
    @include flex-wrap(wrap);
    @include justify-content(center);
    @include align-content(center);
    @include align-items(center);
    @extend .breadcrumb;
    min-height: 30px;
    padding: 0;

    .progress-map-bar {
      cursor: pointer;
      background-color: $progress-map-bar;
      border-right: 1px solid $color-white;
      color: $color-white;
      text-align: center;
      @include order(0);
      @include flex(auto);
      @include align-self(auto);
      @include transition(background-color 300ms);
      min-width: 5px;
      height: 18px;
      font-size: 0.8em;
      margin: 2px 0;

      &.chapter, &.preface, &.appendix {
        text-align: center;
        @include flex(0);
        min-width: 24px;
        height: 22px;
        background-color: darken($progress-map-bar, 5%);

        &.visited {
          background-color: $visited;

          &:hover {
            background-color: $visited-hover;
          }
        }
      }

      &.visited {
        background-color: $visited;

        &:hover {
          background-color: $visited-hover;
        }
      }

      &.active {
        background-color: $bg-active;

        &:hover {
          background-color: $bg-active-hover;
        }
      }

      &:hover {
        background-color: $progress-map-bar-hover;
      }

      &.disabled {
        background-color: $disabled;
        cursor: initial;

        &:hover {
          background-color: $disabled;
        }
      }

      &:last-child {
        border-right: none;
      }
    }
  }
}
