@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';

.course {
  .syndication-style{
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .page-header {
    margin-bottom: 10px;
    animation: fade-in 300ms ease-in-out;
  }

  &__skeleton {
    margin-top: 12px;
  }

  .tab-course-content {
    & > * {
      animation: fade-in 300ms ease-in-out;
    }
  }

  &__content-wrapper {
    position: relative;
    overflow-x: auto;
  }

  &.video-classroom {
    justify-content: center;
    padding-top: 10px;
  }

  &.show-video-refresh-banner {
    display: inherit !important;
  }

  .course-container {
    animation: fade-in 300ms ease-in-out;

    .tab-content {
      padding: $form-padding 0;
    }

    .nav-tabs.nav {
      li {
        a {
          font-weight: 700;
          background: transparent;
          outline: none;

          &:hover {
            color: $brand-primary-hover;
            background: transparent;
          }
        }
      }
    }

    .course-toolbar {
      text-align: right;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      
      .course-btns-container {
        display: flex;
        flex-direction: row;

        @include respond(md, false) {
          flex-direction: column;
        }
      }

      @include respond(md, false) {
        flex-direction: column;
      }

      & > div > :not(:last-child) {
        margin-right: $form-padding;

        @include respond(md, false) {
          margin-right: 0;
        }
      }

      .feedback-toggle {
        @include respond(md, false) {      
          border: 1px solid $btn-info-border;
          margin-bottom: 0.625rem;
          border-radius: unset;
        }
      }

      .ebook-download {
        display: inline-block;
      }
    }

    .launch-button-bundle-course {
      margin-right: 12px;
      padding-left: 36px;
      padding-right: 36px;
    }

    #videoClassroomTabs {
      .lab-controls {
        padding: 25px 0;
      }
    }

    .bundled-course {
      margin-top: 10px;
      position: relative;
      opacity: 0;
      margin-bottom: 70px;

      &--loaded {
        animation: move-in-bottom 400ms ease-in-out;
        opacity: 1;
      }

      .bundled-course-iframe {
        width: 100%;
        height: 90vh;
      }
    }
  }

  .free-tier-redirect-wrapper {
    padding: 25px 0;
    text-align: center;
  }

  .feedback-btn-wrapper {
    display: flex;

    .feedback-toggle {
      margin-left: auto;
      margin-bottom: 0.625rem;
    }
  }
}

.course-loading {
  display: flex;
  height: 50vh;
  align-items: center;
  justify-content: center;
}

.free-tier-message-wrapper .subscription-comparison-table {
  margin-top: 60px;
}

.free-tier-message-wrapper .subscription-comparison-table-heading {
  display: none;
}

#course-tabs {
  margin-top: 20px;

  @include respond(md, false) {      
    margin-top: 5px;
  }
}

.not-found-msg {
  margin-top: 20px;
}

.btn-greyed {
  color: $color-ws-cancel-dark !important;
  background-color: $color-ws-cancel-light !important;
  border-color: $color-ws-cancel-light !important;
}

.btn.has-spinner {
  position: relative;
  width: 180px;
  padding-left: 40px;
  padding-right: 40px;
  transition: padding 1s ease-in;

  .spinner-wrapper {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2px;
    display: inline-block;
    width: 40px;
    opacity: 0;
    transform: scale(0.5);
    transition: opacity 1s ease-in;
    overflow: hidden;

    .css-0 {
      height: 100%;
      position: absolute;
      top: 8px;
    }
  }
}

.btn.has-spinner.spinner-active {
  padding-left: 40px;
  padding-right: 20px;

  .spinner-wrapper {
    opacity: 1;
  }
}

.expert-extras-tab-content {
  padding-bottom: 20vh;
}

.expert-extras-player {
  aspect-ratio: 16 / 9;
}
