@import '../../../styles/variables';

fieldset {
  .favorite-list {
    .favorite-item {
      margin-bottom: 10px;
    }

    .favorite-item {
      width: 100%;
      display: table;
    }

    .favorite-item Link,
    .trash-icon {
      display: table-cell;
    }

    .trash-icon {
      float: right;
    }

    .trash-icon:hover {
      color: red;
    }

    .favorite-item Link {
      cursor: pointer;
    }

    .favorite-icon {
      color: $brand-primary;
      font-size: 24px;
    }
  }
}