.course-overview {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: var(--pf-v5-global--spacer--xs);

  &__toc {
    margin-top: var(--pf-v5-global--spacer--xs);
  }

  &__h3 {
    font-size: 18px;
    color: var(--rhls-color-primary);

    &--dark {
      color: inherit;
      font-weight: bold;
    }
  }

  &__list {
    list-style: none;
  }

  &__collateral-tags {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: var(--pf-v5-global--spacer--xs);
  }

  &__related-course {
    min-height: 56px;
    position: relative;
    gap: var(--pf-v5-global--spacer--md);
    border: 1px solid var(--pf-v5-global--BorderColor--100);
    border-radius: var(--pf-v5-global--BorderRadius--sm);
    max-width: 448px;
    padding: 15px 20px 15px 28px;
    margin-bottom: 12px;

    svg {
      position: absolute;
      top: calc(50% - 15px);
      left: 0;
      margin-left: -15px;
    }
  }
}
