@import "../../../styles/mixins/mediaqueries";

.dashboard-wrapper {
  .mini-card {
    .card-data {
      height: 60px;
      border: 1px solid #dddddd;

      .card-icon {
        width: 60px;
        height: 60px;
        float: left;
        color: white;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .icon-1 {
        background: #20a8d8;
      }

      .icon-2 {
        background: #63c2de;
      }

      .icon-3 {
        background: #ffc107;
      }

      .icon-4 {
        background: #f86c6b;
      }

      .card-info {
        height: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: left;
        padding-left: 10px;

        .bold-data {
          font-weight: bold;
        }
      }
    }

    .flip-mini-card {
      background-color: transparent;
      height: 60px;
      perspective: 1000px;

      /* Remove this if you don't want the 3D effect */
    }

    /* This container is needed to position the front and back side */
    .flip-mini-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      // text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip-mini when you move the mouse over the flip-mini box container */
    .flip-mini-card:hover .flip-mini-card-inner {
      transform: rotateX(180deg);
    }

    /* Position the front and back side */
    .flip-mini-card-front,
    .flip-mini-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;

      /* Safari */
      backface-visibility: hidden;

      p.scaled-text {
        line-height: 95%;
      }
    }

    /* Style the back side */
    .flip-mini-card-back {
      padding: 10px 15px 0px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      background-color: #f8f8f8;
      transform: rotateX(180deg);
      border: 1px solid #dddddd;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }

    .info-button {
      position: absolute;
      left: 240px;

      @include respond(lg, false) {
        left: auto !important;
        right: 16px;
      }

      @include respond(xl, false) {
        left: 190px;
      }
    }
  }

  .medium-card {
    .medium-card-data {
      .medium-card-info {
        height: 150px;
        // align-items: left;
        border: 1px solid #dddddd;

        .medium-card-split-half-display {
          height: 100%;

          .pdf-downloads-wrapper {
            height: 50%;
            border-bottom: solid 1px #dddddd;
            display: flex;
            justify-content: space-evenly;
            align-items: center;

            .pdf-downloads-icon-wrapper {
              width: 38px;
              display: flex;
              flex-direction: row;
              justify-content: center;
              align-items: center;
            }

            .course-book-downloads-text-wrapper {
              width: min-content;
              white-space: nowrap;

              @include respond(lg, false) {
                width: auto !important;
              }
            }
          }

          .lab-hours-summary-wrapper.lab-usage-warning {
            background-color: #fdf7e7;
          }

          .lab-hours-summary-wrapper {
            display: flex;
            flex-direction: column;
            justify-content: center;
            height: 50%;

            .inline-count-up {
              align-self: flex-start;
              margin-left: 1rem;
            }

            .pf-v5-c-progress {
              margin-left: 1rem;

              &__status {
                display: none;
              }
            }

            .progress {
              overflow: hidden;
              height: 10px;
              background-color: #f5f5f5;
              width: 90%;
              margin-bottom: 5px;
              border-radius: 2px;
              align-self: center;

              .progress-bar {
                text-align: right;
                background-color: #08c0fc;
              }
            }
          }
        }

        .medium-card-display {
          height: 110px;
          display: flex;
          flex-direction: row;
          justify-content: space-evenly;
          align-items: center;
        }

        .medium-card-bottom {
          height: 40px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          border-top: solid 1px #dddddd;
        }

        .medium-nr {
          font-size: 50px;
          font-weight: bold;
        }

        .greyed-nr {
          color: #d8d8d8;
        }

        .giant-nr {
          font-size: 70px;
          font-weight: bold;
        }

        .green-text {
          color: #4caf50;
        }
      }
    }

    .medium-card-info-exam {
      height: 150px;
      border: 1px solid #dddddd;

      @include respond(lg, false) {
        height: 200px;
      }

      .medium-card-display-exam {
        height: 110px;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;

        @include respond(lg, false) {
          height: 160px;
          flex-direction: column;
        }

        .exam-data-wrapper {
          width: 40%;
          display: flex;
          flex-direction: column;
          justify-content: space-evenly;
          align-items: stretch;
          height: 100%;

          @include respond(lg, false) {
            width: 60%;
          }

          .exam-data-holder {
            display: flex;

            .exam-data {
              margin-left: auto;
            }

            .bold-data {
              font-weight: bold;
            }
          }
        }
      }

      .medium-card-bottom {
        height: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border-top: solid 1px #dddddd;
      }
    }

    .flip-card {
      background-color: transparent;
      height: 150px;
      perspective: 1000px;

      /* Remove this if you don't want the 3D effect */
    }

    .flip-card-exam {
      background-color: transparent;
      height: 150px;
      perspective: 1000px;

      @include respond(lg, false) {
        height: 200px;
      }
    }

    /* This container is needed to position the front and back side */
    .flip-card-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.8s;
      transform-style: preserve-3d;
    }

    /* Do an horizontal flip when you move the mouse over the flip box container */
    .flip-card:hover,
    .flip-card-exam:hover {
      .flip-card-inner {
        transform: rotateY(180deg);
      }
    }

    /* Position the front and back side */
    .flip-card-front,
    .flip-card-back {
      position: absolute;
      width: 100%;
      height: 100%;
      -webkit-backface-visibility: hidden;

      /* Safari */
      backface-visibility: hidden;
    }

    /* Style the back side */
    .flip-card-back {
      padding: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #f8f8f8;
      transform: rotateY(180deg);
      border: 1px solid #dddddd;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
