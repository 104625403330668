@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

$learning-path-card-icon-width: 70px;

.learning-path-card {
  color: $gray-base;
  cursor: pointer;

  &.panel {
    border-radius: 0;
  }

  .panel-body {
    padding-bottom: 0;
    display: flex;
  }

  >.progress {
    margin: 0;
    margin-top: $form-padding;
    border-radius: 0;

    .progress-bar {
      background-color: $color-primary;
      text-align: right;
      padding-right: 5px;
      padding-left: 5px;
      min-width: 20px;

      &[aria-valuenow="0"] {
        color: $gray-light;
        background: transparent;
        box-shadow: none;
      }
    }
  }

  .learning-path-content-wrapper {
    flex-grow: 1;
    position: relative;
  }

  .learning-path-icon {
    display: inline-block;
    min-width: $learning-path-card-icon-width;
    height: $learning-path-card-icon-width;
    vertical-align: top;
    border-radius: 50%;
    background-color: $color-learning-path;
    padding: 5px;
    margin: 5px $form-padding 0 0;
    position: relative;
    overflow: hidden;

    >.isvg.loaded {

      /* centering the modality icon */
      height: 100%;
      position: relative;
      display: block;

      svg {
        max-height: 75%;
        position: absolute;
        top: 12%;
        left: 10%;
      }
    }
  }

  .learning-path-collateral-description {
    margin-top: $form-padding;
  }

  .learning-path-content {
    display: inline-block;
    width: 100%;
  }

  .learning-path-meta {
    display: flex;
    align-items: center;

    &> :not(:last-child) {
      margin-right: $form-padding;
    }
  }

  .learning-path-info {
    margin-top: $form-padding;
    border-top: 1px solid $color-secondary;
    font-weight: 300;
  }

  .learning-path-title {
    font-weight: 600;
    padding-bottom: 15px;
  }

  .learning-path-actions {
    margin-left: auto;
  }

  .learning-path-view {
    padding: 0 $form-padding;

    svg {
      width: 20px;
    }
  }

  .learning-caret-wrapper {
    float: right;
    height: 50px;
  }

  .learning-path-expand {
    svg {
      width: 15px;
    }
  }

  .learning-path-elements {
    margin-top: 20px;
    width: 100%;
  }
}

.vertical-timeline::before {
  background: #A5A5A5;
  height: calc(100% - 190px);
  margin-left: -70px;
  margin-top: 72px;

  @media (max-width: $screen-sm-min) {
    height: calc(100% - 230px);
  }
}

.vertical-timeline {
  max-width: 1170px;
  margin: 0;
  padding: 0;
}

.vertical-timeline-element-content {
  box-shadow: none;
  padding: 0;
}

.vertical-timeline-element {
  margin: 0.1em 0 0;
}

.panel {
  margin-bottom: 0;
}

.vertical-timeline-element--work {
  margin-left: -60px;
}

.vertical-timeline-element-icon {
  top: 50px;
  width: 20px;
  height: 20px;
  box-shadow: 0 0 0 4px #a5a5a5, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);
}

.expanded-icon {
  border: 3px solid #a5a5a5;
}

.learning-path-delete-btn {
  color: white !important;
  background-color: #333 !important;
  border-color: $gray-dark !important;
}

@media only screen and (min-width: 768px) {
  .vertical-timeline-element-content p {
    font-size: 16px;
  }

  .vertical-timeline-element-content h2 {
    font-size: 20px;
  }

}
