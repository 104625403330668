@import "../../../../../styles/mixins/mediaqueries";

.premvt-session-card {  
  .glyphicon-exclamation-sign {
    color: #21a8d7;
  }

  @include respond(sm, false) {
    margin-left: 10px;
  }
}
