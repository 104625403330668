@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

.date-picker-input-wrapper {
  display: flex;
  justify-content: center;

  .date-input-group {
    width: 80%;
    z-index: 1;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }

    .due-date-text {
      background: transparent;
      border: 0px;

      @media (max-width: $screen-sm-max) {
        display: none;
      }
    }

    .due-date-btn-group {
      background-color: #08c0fc;
      border: 1px solid #08c0fc;

      .date-btn {
        background-color: #08c0fc;
        color: #ffffff;
        border: 1px solid #08c0fc;

        border-radius: 0rem 0.25rem 0.25rem 0rem;
        min-width: 25px;
        padding: 0;
      }
    }
  }

  .DayPickerInput {
    input {
      color: $input-color;
      text-align: center;
    }

    input:disabled {
      background-color: #e3e3e3;
    }

    .invalid-date-control {
      border: 1px solid red;
    }
  }

  .DayPickerInput-Overlay {
    top: calc(100% - 335px);
    height: 300px;
  }

  .DayPicker {
    font-size: inherit;
  }
}