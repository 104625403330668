@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';
@import '../../../components/PremiumFilter/styles';

.live-session-container {
  margin-top: 15px;

  .live-session-list-container {
    display: inline-block;
    width: 100%;
  }

  .live-session-list-wrapper {
    @media (max-width: $screen-sm-max) {
      border-top: 1px solid #b7b7b7;
    }
  }

  .live-session-pagination {
    text-align: center;
  }
}
