@import '../../../../styles/variables';

.lab-webpanel-row {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  .lab-webapp-panel {
    box-sizing: border-box;
    padding: 0px !important;
    margin-bottom: 10px !important;
    display: flex;
    flex-direction: column;
    flex: 0 0 48%;
    margin-left: 8px;
    margin-right: 8px;

    .filter-panel-toggle {
      cursor: pointer;

      .filter-panel-heading {
        background-color: #f5f5f5;

        .filter-panel-heading-title {
          display: flex;

          .btn-primary {
            color: inherit;
            background-color: #fff;
            border-color: #ddd !important;
            border-radius: 5px;
            border: 1px solid transparent;
            padding: 10px 10px;
          }

          .filter-panel-heading-icon {
            display: flex;
            margin-right: auto;

            svg {
              width: 15px;
              margin-right: 10px;
            }
          }
        }
      }
    }
  }
}
