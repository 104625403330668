@use 'sass:math';
@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';

$hero-slider-height: 300px;
$hero-slider-height-mobile: 350px;

.hero-slider {
  height: $hero-slider-height;

  @include respond(sm, false) {
    height: $hero-slider-height-mobile;
  }

  .carousel-inner {
    height: 100%;
    background-size: cover;
    background-position: center;

    .item {
      height: $hero-slider-height;
      @include respond(sm, false) {
        height: $hero-slider-height-mobile;
      }

      img {
        display: block;
        width: 100vw;
        height: $hero-slider-height;

        @include respond(sm, false) {
          height: $hero-slider-height-mobile;
          display: none;
        }
        object-fit: cover;
      }
    }
  }

  .carousel-indicators {
    z-index: 1;

    li {
      border-color: $gray-light;
    }
  }

  .carousel-control {
    width: 50px;
  }

  .btn-wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    @include respond(sm, false) {
      align-items: center;
      justify-content: center;
      margin-top: auto;
      padding-bottom: 16px;
    }
  }

  .carousel-caption {
    text-align: left;
    left: 0;
    width: 100%;
    text-shadow: none;

    @include respond(sm, false) {
      top: 0;
    }

    .container-grid-responsive {
      @include respond(sm, false) {
        height: 100%;
      }
    }

    .col-xs-12 {
      @include respond(sm, false) {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }

    button {
      margin-top: 12px;
      margin-bottom: 3px;
    }

    .text-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @include respond(sm, false) {
        max-height: math.div($hero-slider-height-mobile, 2);
        overflow-y: auto;
        font-size: 0.9em;
      }
    }

    p {
      max-width: 30em;
      font-size: 1.2em;
      font-weight: 300;
      color: white;

      @include respond(lg, false) {
        font-size: 1em;
      }

      @include respond(sm, false) {
        color: black !important;
      }
    }

    .new-text {
      line-height: 1.7em;
    }

    h2 {
      margin-bottom: 15px;

      @include respond(sm, false) {
        margin-top: 0px;
        color: black !important;
        font-size: 24px;
      }
    }
  }
}
