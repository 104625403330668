@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

.request-session-form {
  padding-right: $grid-distance;

  .form-inline {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    /* Let the timezone picker take all the space */
    #timezone-form-group {
      flex-grow: 1;

      /* If the screen is too small, take up all the space, skipping it to the next row. */
      @media (max-width: $screen-md-max) {
        width: 100%;
      }
    }

    /* Give some margin to the preferred time picker. */
    #preferred-time-form-group {
      margin-right: $grid-distance;

      /* If the screen is too small, make it take the rest of the row's space and make the timezone picker skip to the next row. */
      @media (max-width: $screen-md-max) {
        margin-right: unset;
        flex-grow: 1;

        select {
          width: 100%;
        }
      }
    }

    /* Give some margin to the date range picker and make the selector take all the space in the div. */
    #date-range-form-group {
      margin-right: $grid-distance;

      select {
        width: 100%;
      }

      /* When the screen is very small, make each picker be in a different row, taking up all the available space. */
      @media (max-width: $screen-sm-max) {
        width: 100%;
        margin-right: unset;
      }
    }

    #notify-me-checkbox {
      margin-top: $grid-distance;
    }

    .label-form {
      font-size: 14px;
      margin-bottom: 0;
    }

    .bottom-element {
      margin-top: $grid-distance;
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: stretch;
      align-content: stretch;
      width: 100%;
    }

    .form-preferred-time {
      min-width: 175px;
    }

    .timezone-picker {
      width: 100%;
    }

    .black-cancel-button {
      color: white;
      background-color: #333;
      border-color: $gray-dark;
    }

    .form-group select, .form-group input {
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="30" viewBox="0 0 24 24" width="20" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>');
      background-repeat: no-repeat;
      background-position-x: 99%;
    }

    .checkbox {
      margin-top: $grid-distance;

      label {
        input {
          vertical-align: top;
          margin-right: $grid-distance;
        }
      }
    }

    .timezone-picker-textfield {
      font-size: 14px;
      font-family: $font-family-sans-serif;
      font-weight: 400;

      input {
        color: #646464;
        border-radius: 0;
        border: 1px solid #ccc;
        width: 100%;
      }
    }
  }
}
