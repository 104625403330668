@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../styles/variables';
@import '../../components/Catalog/Filters/styles';

.custom-skillpath-container {
  margin-top: 15px;

  .flex-row {
    display: flex;
    flex-direction: row;

    .flex-full-col {
      flex: 1;
    }

    .flex-select-path-type-col {
      flex: 1;
    }

    .flex-no-filter-col {
      flex: 1;
      max-width: $filter-width;
      @media (max-width: $screen-sm-max) {
        margin-left: 15px;
        max-width: 100%;
      }
    }

    .available-offering-title {
      text-align: center;
    }

    @media (max-width: $screen-sm-max) {
      flex-direction: column;
    }
  }

  .offering-list-pagination {
    text-align: center;
  }
}
