// Use this if we need to customize global patternfly style variables for the whole app
:root {
  .pf-v5-c-spinner {
    --pf-v5-c-spinner--Color: #be0000;
  }

  .pf-v5-c-tabs__item.pf-m-current {
    --pf-v5-c-tabs__link--after--BorderColor: #ee0000;
  }

}
