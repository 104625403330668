.dashboard-wrapper {
  margin-bottom: 100px;

  .download-options-wrapper {
    height: 39px;
    margin: 15px 0 15px;
    display: block;

    .svg-holder {
      width: 48px;
      height: 48px;
      margin-left: auto;
    }
  }

  .svg-holder span:focus {
    outline: none;
    box-shadow: none;
  }
}
