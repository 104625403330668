@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../styles/variables';
@import '../../styles/mixins/mediaqueries';

.header {
  z-index: 101;

  .logo {
    height: 56px;
  }

  .button-link {
    margin: 0;
    text-align: left;
    padding-left: 0;

    &--selected {
      color: var(--pf-v5-global--palette--black-700);
      font-weight: bold;
    }

    &:hover {
      color: var(--pf-v5-global--primary-color--dark-100);
      text-decoration: underline;
    }
  }

  .avatar {
    vertical-align: sub !important;
  }

  .external-site {
    border: 1px solid $color-ws-cancel-light;
    padding: 1rem;
    display: block;
    margin-bottom: var(--pf-l-flex--spacer);

    &:hover {
      background-color: $color-ws-cancel-light;
    }

    img {
      height: 40px;
    }
  }

  .logout-text {
    max-width: 500px;
    white-space: break-spaces;
  }

  .pf-v5-c-accordion__toggle.pf-m-expanded {
    --pf-v5-c-accordion__toggle--before--BackgroundColor: #ee0000; // $color-primary doen't work
  }

  .pf-v5-c-accordion__expanded-content.pf-m-expanded {
    --pf-v5-c-accordion__expanded-content-body--before--BackgroundColor: #ee0000;

    .pf-v5-c-button.pf-m-link.pf-m-inline {
      width: 100%;
      font-weight: 400;
      padding: calc(var(--pf-v5-c-accordion__toggle--PaddingTop) / 2);

      &:hover {
        text-decoration: none;
        background: var(--pf-v5-global--BackgroundColor--200);
      }
    }
  }

  @include respond(xl, false) {
    .menu {
      top: 150px;
    }
  }

  @include respond(lg, false) {
    .account-menu-container {
      justify-content: flex-start;

      .logout-text {
        margin-top: 1rem;
      }
    }
  }

  &__menu-item {
    padding: var(--pf-v5-global--spacer--lg);
  }
}

.topbar {
  padding: 0 9px 0 10px;
  border-bottom: 1px solid $gray;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 101;
  background-color: var(--pf-v5-global--BackgroundColor--dark-100);

  @include respond(md, false) {
    display: none;
  }

  menu {
    margin: 0;
    padding: 0;

    ul.nav {
      display: flex;
      align-items: center;
    }

    li {
      padding: 0;
      margin: 0;

      a {
        font-size: 14px;
        display: block;
        color: #999;
        text-decoration: none;
        outline: none;
        padding: 6px 0;
        border-top: 2px solid transparent;
        margin-right: 0.7rem;

        &:hover,
        &:active,
        &:focus {
          border-top-color: #999;
          background-color: transparent;
        }

        #user-switcher {
          svg {
            vertical-align: middle;
            margin-right: 0.3rem;
          }
        }
      }
    }
  }
}

.early-access-panel {
  display: inline-flex;
  flex: 1;
  align-items: center;
  padding: 0 var(--pf-v5-global--spacer--md);
}

.early-access-link {
  background-color: var(--pf-v5-global--palette--blue-400);
  padding: 8px 16px;
  height: 50%;
  border-radius: 3px;
  display: flex;
  align-items: center;
  color: white;

  &:hover,
  &:active,
  &:focus {
    background-color: var(--pf-v5-global--palette--blue-500);
    text-decoration: none;
    color: white;
  }
}

.language-menu {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: var(--pf-v5-global--spacer--lg);

  @include respond(sm, false) {
    grid-template-columns: 1fr;
  }
}

.notification-badge-wrapper {
  position: absolute;
  top: 10px;
  right: -10px;
}

.notification-icon-badge {
  top: -35px;
  right: -2px;

  @media (max-width: $screen-sm-min) {
    top: -21px;
    right: -11px;
  }
}
