@import '../../../../../styles/variables';

.mgmt-course-progress-table {
  .custom-table-header-main {
    max-width: fit-content;

    button {
      width: max-content;
    }
  }

  .custom-table-header {
    background-color: #4E745C;
    color: $color-white;
    vertical-align: middle !important;
    cursor: default;
    max-width: fit-content;
    font-size: small;
    font-weight: normal;
  }
}
