@import '../../../styles/variables';

.learning-path-widget {
  border: none !important;

  .panel-heading {
    color: white;
    background-color: $color-learning-path !important;
    border-radius: 0;

    .panel-title {
      color: white;
    }
  }

  & > .panel-collapse > .panel-body {
    padding: $form-padding 0;
  }

  .panel-footer {
    text-align: center;
    cursor: pointer;
    background-color: $gray-lighter;
  }

  .no-enrollments-msg {
    text-align: center;
  }

  .learning-path-widget-expand {
    display: block;
    float: right;

    svg {
      fill: white;
      width: $form-padding;
    }
  }
}
