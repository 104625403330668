@keyframes fade-in {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes move-in-from-bottom {
  0% {
    opacity: 0;
    transform: translateY(4rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}


@keyframes move-in-from-top {
  0% {
    opacity: 0;
    transform: translateY(-4rem);
  }

  100% {
    opacity: 1;
    transform: translate(0);
  }
}

@keyframes grow {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}
