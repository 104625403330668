.section-wrapper {
  list-style: none;
  width: 100%;
  padding: 0;
  margin-bottom: 0;

  .section-header {
    background-color: white;

    .section-header-btn {
      border-bottom: 1px solid #aaa;
      background-color: inherit;
    }

    .section-header-btn:focus {
      outline: none;
    }

    .section-header-btn.archives {
      line-height: inherit;
    }

    .active {
      color: white;
      background-color: #ee0000;
    }
  }
}

.open-support-from-pane-btn {
  color: #08c0fc;
  height: 2.5em;
  line-height: 2.5em;

  &:hover {
    background-color: #08c0fc;
    color: white;
  }
}
