.ordering-buttons-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #e6e6e6;
  width: 25px;
  padding: 10px 15px;

  img {
    min-width: 20px;
  }
}