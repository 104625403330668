@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';

#org-form-div {
  background-color: rgb(230, 230, 230);
  padding-left: 22px;
  padding-right: 25px;
  padding-bottom: 5px;
  margin-bottom: 10px;

  .org-delete-btn {
    background-color: #000000;
    color: #ffffff;
  }

  button {
    margin-left: 5px;
  }
}

textarea {
  resize: none;
}
