@use 'sass:math';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';
@import '~react-bootstrap-typeahead/css/Typeahead.css';

.typeahead-panel-card {
  .search-icon {
    width: 45px;
    height: 45px;
    min-height: 45px;
    min-width: 45px;
    
    svg {
      width: 40px;
      height: 40px;
    }
  }
  @media (max-width: $screen-sm-max) {
    .search-icon {
      display: none;
    }
  }

  .rbt-menu > .dropdown-item {
    color: $gray;
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
  }

  .panel-footer {
    cursor: default !important;
    padding: 0 $grid-distance $grid-distance;
    border-bottom-right-radius: 0;

    .footer-elements-container {
      display: flex;
      justify-content: flex-end;

      @media (max-width: $screen-sm-max) {
        flex-direction: column;
      }
    }

    .actions-container {
      margin-top: $grid-distance;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: flex-end;
      align-items: center;
      align-content: stretch;

      @media (max-width: $screen-sm-min) {
        margin-top: 0;
        padding-top: $grid-distance;
        padding-right: $grid-distance;
        padding-bottom: $grid-distance;
        align-items: flex-end;
      }

      a,
      button {
        height: 38px;
        background-color: $color-white;
        padding: 7px 20px 7px 20px;
        border: 1px solid #ccc;
        border-radius: 4px;
        color: black;
        text-transform: uppercase;
        font-weight: bold;
        font-size: 14px;
      }

      a:hover,
      .btn-link:hover,
      button:hover {
        text-decoration: none;
        background-color: $gray-lighter;
      }
    }

    .actions-container > * {
      margin-left: math.div($grid-distance, 2);
      margin-right: math.div($grid-distance, 2);

      &:last-child {
        margin-right: 0;
      }
    }

    @media (max-width: $screen-sm-min) {
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }
}
