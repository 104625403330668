@import '../../../styles/mixins/mediaqueries';

.reports {
  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 40vh;
    flex: 1;
    height: 100%;
  }

  &__tabs {
    padding: 0 var(--pf-v5-global--spacer--3xl) !important;
    margin-top: var(--pf-v5-global--spacer--lg);

    @include respond(md, false) {
      padding: 0 var(--pf-v5-global--spacer--sm) !important;
    }
  }

  &__old-reports {
    margin-top: var(--pf-v5-global--spacer--3xl);
  }
}
