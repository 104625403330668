@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';
@import '../../../styles/mixins/mediaqueries';

.dashboard-wrapper {
  margin-bottom: 100px;

  .svg-holder span:focus {
    outline: none;
    box-shadow: none;
  }
}

.mgmt-dashboard-tabs {
  display: flex;
  justify-content: center;

  @include respond(sm, false) {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  @include respond(sm) {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .pf-v5-c-tabs__item.pf-m-action::after,
  .pf-v5-c-tabs__link::after {
    border-color: #ee0000;
  }
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__list {
  max-width: 100%;
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item {
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item:first-child {
  margin-top: 0;
}

.pf-v5-c-tabs.pf-m-vertical .pf-v5-c-tabs__item:last-child {
  margin-bottom: 0;
}

.mgmt-dashboard-container {
  padding-top: var(--pf-v5-global--spacer--sm);
  padding-right: var(--pf-v5-global--spacer--3xl);
  padding-bottom: var(--pf-v5-global--spacer--3xl);
  padding-left: var(--pf-v5-global--spacer--3xl);

  @include respond(sm) {
    padding-right: var(--pf-v5-global--spacer--md);
    padding-left: var(--pf-v5-global--spacer--md);
  }

  ul {
    margin-bottom: 0;
  }
}

.mgmt-dashboard-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 50vh;
}
