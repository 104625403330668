@import "../../../../styles/mixins/mediaqueries";

.course-progress {
  display: flex;
  flex-direction: column;
  min-height: 570px;

  @include respond(lg) {
    flex-direction: row;
  }

  &__filters {
    flex: 2;
    margin-bottom: var(--pf-v5-global--spacer--md);

    @include respond(lg) {
      margin-right: var(--pf-v5-global--spacer--md);
      margin-bottom: 0;
    }
  }

  &__table-container {
    flex: 6;

    &--with-min-height {
      min-height: 600px;
    }

  }

  &__table {
    border: 1px solid var(--pf-v5-global--BorderColor--100);
  }

  &__filter {
    margin-bottom: var(--pf-v5-global--spacer--sm);
  }

  &__table-row-number {
    margin-right: 8px;
    font-weight: bold;
  }

  &__table-row {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: var(--pf-v5-global--spacer--sm);
    font-weight: light;
  }

  &__no-margin {
    margin: 0;
  }
}
