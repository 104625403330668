@import '../../../../../styles/variables';

.matching {
  .step {
    list-style-type: none;
  }

  .chapter .informaltable {
    th,
    td {
      padding: 5px;
    }
  }

  .Droppable {
    height: 50px;
    background: $gray-lighter;
  }

  .interactive_buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 0;

    button {
      margin-right: 10px;
    }
  }

  .interactive_buttons button:last-child {
    margin-left: auto;
    margin-right: 0;
  }

  .droppable {
    height: 50px;
    background: $gray-lighter;
  }

  .droptarget {
    display: block;
  }

  .box {
    min-height: 20px;
    width: 100%;
  }

  .roc_matching_label {
    margin: 5px;
    font-size: 100%;
    display: inline-block;
    background-color: scale-color($brand-primary, $saturation: -35%);
    padding: 0.3em 0.6em;
    font-weight: normal;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 0.25em;

    &[draggable].roc_matching_label {
      cursor: move;

      &:hover {
        cursor: move;
      }
    }
  }

  .correct {
    background-color: #5cb85c;
  }

  .incorrect {
    background-color: #d9534f;
  }
}