@import '../../../../../styles/variables';
@import '../../../../../styles/mixins/mediaqueries';

.multi-choice {
  padding: 0px 0;

  li.step {
    list-style-type: none;
  }

  .interactive_buttons {
    display: flex;
    justify-content: space-between;
    margin: 15px 0 0;

    button {
      margin-right: 10px;
    }
  }

  .interactive_buttons button:last-child {
    margin-left: auto;
    margin-right: 0;
  }

  td {
    &.ans_label {
      padding: 10pt;
      padding-left: 20pt;
      width: 25pt;
    }

    &.ans_input {
      padding: 10pt;
      padding-left: 0;
      width: 10pt;
    }

    &.ans_text {
      padding: 10pt;

      p {
        margin-bottom: 0;
      }
    }

    .ques_label {
      padding: 0 10px;
    }
  }

  .question {
    td {
      &:first-child {
        width: 15px;
      }
    }
  }

  .multi_question {
    border: 1pt solid $gray-lighter;
    border-radius: 5pt;
    margin: 10pt;
    padding: 10pt;

    @include respond(sm, false) {
      margin: 10px 0;
    }

    

    .correct {
      .ans_text {
        border: 2pt solid green;
        border-radius: 20pt;
      }
    }

    .incorrect{
      .ans_text{
        border: 2pt solid red;
        border-radius: 5pt;
        text-decoration: line-through;
      }
    }

  }

}
