.navigation {
  display: flex;
  justify-content: space-between;
  margin: 25px 0 20px;

  .pf-v5-c-button {
    padding: var(--pf-v5-global--spacer--form-element)
    var(--pf-v5-global--spacer--md);
  }
}
