@import 'variables';
@import '~bootstrap-sass/assets/stylesheets/bootstrap';
@import 'inc/fonts';
@import 'inc/buttons';
@import 'inc/panels';

@import 'vendors';
@import 'patternfly_app_theme';
@import 'animations';
@import 'mixins/mediaqueries';
@import 'mixins/flexbox';
@import 'inc/deployment';
@import 'inc/role_lms';
@import 'inc/navbar';
@import 'inc/alert';
@import 'inc/tables';
@import 'inc/tabs';
@import 'inc/form';
@import 'inc/course_toc';
@import 'inc/icons';
@import 'inc/course_catalog';
@import 'inc/progress_map';
//@import "inc/content";
@import 'inc/early_access';
@import 'inc/notes';
// @import "inc/seminar";
@import 'inc/print';

// CSS variables (can be used in JS as well)
:root {
  --rhls-color-primary: #{$color-primary};
  --rhls-color-secondary: #{$color-secondary};
  --rhls-color-secondary-light: #{$color-secondary-light};
  --rhls-color-secondary-dark: #{$color-secondary-dark};
  --rhls-color-accent-blue: #{$color-accent-blue};
  --rhls-color-tertiary: #{$color-tertiary};
  --rhls-color-light-purple: #{$color-light-purple};
  --rhls-color-white: #{$color-white};
  --rhls-color-green: #{$color-green};
  --rhls-color-course: #{$color-course};
  --rhls-color-class: #{$color-class};
  --rhls-color-expert-seminar: #{$color-expert-seminar};
  --rhls-color-learning-path: #{$color-learning-path};
  --rhls-color-exam: #{$color-exam};
  --rhls-color-prelim-exam: #{$color-prelim-exam};
  --rhls-color-remote-exam: #{$color-remote-exam};
  --rhls-color-lab: #{$color-lab};
  --rhls-brand-primary: #{$brand-primary};
  --rhls-brand-primary-hover: #{$brand-primary-hover};
  --rhls-brand-primary-light: #{$brand-primary-light};
  --rhls-brand-danger: #{$brand-danger};
  --rhls-color-ws-cancel-dark: #{$color-ws-cancel-dark};
  --rhls-color-ws-cancel-light: #{$color-ws-cancel-light};
  --rhls-gray-base: #{$gray-base};
  --rhls-gray-darker: #{$gray-darker};
  --rhls-gray-dark: #{$gray-dark};
  --rhls-gray: #{$gray};
  --rhls-gray-light: #{$gray-light};
  --rhls-gray-lighter: #{$gray-lighter};
  --rhls-gray-lightest: #{$gray-lightest};
  --rhls-text-color: #{$text-color};
  --rhls-link-color: #{$link-color};
  --rhls-link-color-hover: #{$link-color-hover};
  --rhls-btn-primary: #{$btn-primary};
  --rhls-btn-info-bg: #{$btn-info-bg};
  --rhls-btn-info-color: #{$btn-info-color};
  --rhls-btn-info-border: #{$btn-info-border};
  --rhls-btn-secondary: #{$btn-secondary};
  --rhls-btn-secondary-border: #{$btn-secondary-border};
  --rhls-font-path: #{$font-path};
  --rhls-img-path: #{$img-path};
  --rhls-icon-font-path: #{$icon-font-path};
  --rhls-font-family-sans-serif: #{$font-family-sans-serif};
  --rhls-base-selector: #{$base_selector};
  --rhls-page-selector: #{$page_selector};
  --rhls-seminar-selector: #{$seminar_selector};
  --rhls-secondary-red-color: #{$secondary-red-color};
  --rhls-color-primary-hover: #{$color-primary-hover};
  --rhls-dark-text: #{$dark-text};
  --rhls-early-access-primary-color: #{$early-access-primary-color};
  --rhls-favorite-icon-color: #{$favorite-icon-color};
  --rhls-favorite-icon-font-size: #{$favorite-icon-font-size};
  --rhls-note-default-color: #{$note-default-color};
  --rhls-note-references-color: #{$note-references-color};
  --rhls-note-important-color: #{$note-important-color};
  --rhls-note-warning-color: #{$note-warning-color};
  --rhls-note-comparison-color: #{$note-comparison-color};
  --rhls-form-padding: #{$form-padding};
  --rhls-border-color: #{$border-color};
  --rhls-background-color: #{$background-color};
  --rhls-grid-distance: #{$grid-distance};
  --rhls-color-early-access: $color-accent-blue;
}


html {
  // work-around in order for patternfly to work properly, since bootstrap sets html font-size to 10px leading to rem issues
  font-size: var(--pf-v5-global--root--FontSize, unset) !important;
}

body {
  font-family: $font-family-sans-serif;
  font-size: 16px;
  line-height: 22.576px;
  font-weight: 400;
  color: $text-color;
  background-color: $color-white;
  font-display: fallback;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // overflow-x: auto;
}

.app-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.reset {
  margin: 0;
  padding: 0;
  list-style: none;
}

.col-border-row {
  margin-left: -30px;
  margin-right: -30px;
}

.col-border {
  .col-border {
    padding-left: 30px;
    padding-right: 30px;
  }
}

.row-eq-height {
  @include flexbox;
}

.band {
  padding: 60px 0 80px;
}

.band-alt {
  background: #f0f0f0;
}

.card {
  padding: 30px;
  background: #252527;
  color: $color-white;
}

.subheading,
.card-heading {
  font-size: 18px;
  line-height: 25.398px;
  font-weight: 600;
}

.card-white {
  color: #252527;
  background: $color-white;
}

.login-form {
  .lostPasswordLink {
    margin-left: 15px;
  }
}

.form-control {
  border-radius: 0;
}

.informaltable,
.table-contents {
  overflow-x: auto;

  th,
  td {
    padding: 5px;
  }
}

.course-progress-first-header {
  display: flex;
  flex-direction: row;
  align-items: center;

  .sort-btn {
    margin-right: 5px;
    margin-left: auto;
    cursor: pointer;
    height: 35px;
    outline: none;
  }
}

.table-horiz-scroll {
  overflow: auto;
  display: block;
  table-layout: auto;

  .react-bootstrap-table > table > tbody > tr > td.scroll-fix,
  .react-bootstrap-table > table > thead > tr > th:first-child {
    position: -webkit-sticky;
    position: sticky;
    left: -2px;
    background-color: white;
  }
}

.with-info-icon {
  color: #08c0fc;
  margin-left: 15px;
}

.display-flex {
  display: flex;
  align-items: center;
}

.container-grid-responsive {
  @include container-fixed;
  width: 100%;

  @include respond(lg) {
    width: $container-md;
  }

  @include respond(xl) {
    width: $container-lg;
  }
}

.video-classroom-player {
  height: 700px;
}

.vertical-timeline::before,
.premiumvt-class .premium-class-elements .vertical-timeline::before,
.vertical-timeline-element-icon {
  @include respond(sm, false) {
    display: none;
  }
}

.vertical-timeline-element-content {
  @include respond(sm, false) {
    margin-left: 0px;
  }
}

.vertical-timeline {
  @media (max-width: 380px) {
    width: 100%;
  }
}

.premiumvt-class .vertical-timeline-element-content {
  @include respond(sm, false) {
    background-color: #fafafa;
  }
}

.skills-path-card #panel-card--body {
  @include respond(sm, false) {
    background-color: #fafafa;
  }
}

.vertical-timeline-element-content p {
  @include respond(sm, false) {
    font-size: 16px;
  }
}

.navigation {
  .keep-text-case {
    text-transform: unset !important;
    font-size: 16px;
    font-weight: unset;
  }

  span {
    .right-page-navigation {
      padding-left: 4px;
      margin-left: 3px;
      right: 0;
    }

    .left-page-navigation {
      padding-right: 4px;
      margin-right: 3px;
    }

    svg {
      position: relative;
      top: 1px;
    }
  }
}

.brand-button {
  background-color: $color-primary;
  color: #ffffff;
  height: 2.5rem;
  padding: 0.5rem 1.5rem;

  @include respond(md, false) {
    width: 100%;
    margin-bottom: 0.625rem;
  }
}

.disabled-patternfly-btn {
  color: var(--pf-v5-global--disabled-color--100) !important;
  background-color: var(--pf-v5-global--disabled-color--200) !important;
}

.premVidFreeTierConatiner {
  margin-bottom: var(--pf-v5-global--spacer--md);
  border: solid 1px var(--pf-v5-global--BorderColor--100);
}
