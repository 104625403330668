@import '../../../styles/variables';

.profile-form-alert {
  margin-top: var(--pf-v5-global--spacer--lg);
  margin-bottom: var(--pf-v5-global--spacer--3xl);
}

.profile-form {
  .timezone-picker {
    width: 100%;
  }

  .profile-header {
    text-align: center;
    margin-top: 50px;
    background-image: url('./../../../img/profile_bg.png');
    background-size: cover;
    background-repeat: no-repeat;

    .profile-header-avatar {
      margin-top: -50px;
    }

    .profile-header-name {
      color: white;

      .profile-header-name-text-container {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  .disabled-info {
    background-color: $gray-lighter;
  }

  .input-with-btn {
    padding-left: 0;
  }

  .row {
    padding: 10px 0;

    .optout-premvt-info-button {
      margin: 0 0 8px 5px;
      padding: 0;
      border-radius: 50%;
      border: 0;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }

    .form-group {
      .control-label {
        padding-top: 5px;
      }

      .btn.cc-email-action-btn {
        padding: 5px 6px 1px 6px;
        border: 0px transparent;
        border-radius: 3px;
      }

      .select-default-country {
        .react-selectize.root-node {
          width: 100%;
        }
      }
    }
  }
}

.updated {
  display: inline-block;
  color: grey;
  font-family: 'RedHatText', 'Overpass', 'Open Sans', Helvetica, sans-serif;
  font-size: 16px;
}

.updated.error {
  color: red;
}

input[hidden] {
  display: none !important;
}
