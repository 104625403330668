@import '../../../styles/mixins/mediaqueries';

.course-versions-btn {
  position: relative;

  .dropdown-toggle {
    font-size: 100%;
    font-weight: normal;
    text-transform: none;

    @include respond(md, false) {
      width: 100%;
    }
  }

  .dropdown-menu {
    width: 100%;
  }

  .tooltip.bottom {
    width: 100%;

    .tooltip-inner {
      max-width: 100%;
      padding: 8px 12px;
    }

    .tooltip-arrow {
      border-bottom-color: #004153;
    }
  }

  .tooltip-container {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;

    .tooltip-target {
      display: block;
      height: 1px;
      width: 100%;
      position: absolute;
      top: 0;
    }
  }

  .btn-group {
    @include respond(md, false) {
      width: 100%;
      margin-bottom: 0.625rem;
    }
  }
}