@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../styles/variables';
@import '../../../components/PremiumFilter/styles';

.premium-coa-wrapper {
  .page-title {
    text-align: center;
  }

  .coa-banner {
    background-image: url('./../../../img/blue-background.png');
    background-repeat: no-repeat;
    background-size: 100%;
    height: 80px;

    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .blue-banner {
    background-image: url('./../../../img/blue-background.png');
  }

  .red-banner {
    background-image: url('./../../../img/red-background.png');
  }

  .course-dropdown-container {
    display: flex;
    justify-content: center;

    .form-group {
      width: 80%;

      .react-selectize {
        width: 100%;
      }
    }
  }

  .premium-coa-container {
    margin-top: 15px;
    text-align: center;

    .coa-list-container {
      display: inline-block;
      width: 80%;
      text-align: left;

      .x-icon:before {
        content: '\2715';
        font-size: x-small;
      }

      .flex-list {
        display: flex;

        border-left: 1px solid #eaeaea;
        border-right: 1px solid #eaeaea;
        border-top: 1px solid #eaeaea;
        padding: 5px;

        .flex-row {
          display: flex;
        }

        .flex-column-icon {
          flex: 0;

          svg {
            height: 30px;
            width: 30px;
          }
        }

        .flex-column {
          flex: 1;
          align-self: center;

          .col-sm-2 {
            display: none;

            .sp-sm-header {
              color: $gray-dark;
            }
          }
        }

        @media (max-width: $screen-sm-max) {
          flex-direction: column;

          .flex-column-icon {
            display: none;
          }

          .flex-row {
            flex-direction: column !important;
          }

          .flex-column {
            align-self: initial;

            .col-sm-2 {
              display: inline !important;

              .sp-sm-header {
                color: $gray-dark;
              }
            }
          }
        }
      }

      .coa-header {
        background-color: #f8f8f8;

        @media (max-width: $screen-sm-max) {
          display: none;
        }

        .flex-column {
          font-weight: bold;
        }
      }

      .coa-buttons {
        text-align: right;
        border: 1px solid #b7b7b7;
        padding: 5px;
        background-color: #f8f8f8;
        margin-bottom: 10px;

        .btn-warning {
          background-color: red;
          border-color: red;
        }

        .view-loader-wrapper {
          width: 100%;
          height: 38px;
          border: 1px dashed #000000;
          border-radius: 0.25rem;

          .spinner-wrapper {
            transform: scale(0.7, 0.7);
          }
        }
      }
    }

    .schedule-pagination {
      text-align: center;
    }
  }
}
