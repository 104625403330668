@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';
@import "../../../../styles/mixins/mediaqueries";

.mgmt-csk-other-filter {
  padding: 15px;
  padding-left: 0px;
  display: flex;
  border-bottom: 1px solid #ccc;
  margin-bottom: 15px;
  border-top: 1px solid #ccc;
  margin-top: 15px;
  @media (max-width: $screen-sm-max) {
    flex-direction: column;
  }

  .filter-row {
    display: inline-flex;
    flex-direction: row;
    margin-right: 15px;

    &:first-child {
      margin-bottom: 15px;
    }

    .span-label {
      padding-right: 15px;
      font-weight: bold;
    }

    @include respond(sm, false) {
      display: block;
    }
  }

  .dropdown {
    .dropdown-menu {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      overflow-y: auto;
      max-height: 300px;

      li > a {
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    .mgmt-csk-filter-dropdown {
      text-transform: none;
      font-weight: normal;
      padding: 5px 15px;
      border-color: rgb(240, 240, 240);
      border-bottom-color: rgb(138, 141, 144);
      box-sizing: border-box;
      color: rgb(21, 21, 21);
      font-style: normal;
    }

    .dropdown-label {
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      display: inline-block;
      text-align: left;

      @include respond(sm, false) {
        width: 17.6em;
      }
    }
  }
}
