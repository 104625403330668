// #{$page_selector} {

@mixin note($color, $icon) {
  border-color: $color;

  &:before {
    background-color: $color;
  }

  h3.title {
    &::before {
      background: url("#{$img-path}/admonitions/#{$icon}.svg") no-repeat 0 0;
      height: $alert-padding * 4;
      width: $alert-padding * 4;
      top: -12px;
      left: -$alert-padding * 4;
    }
  }
}

div {
  &.note,
  &.important,
  &.warning,
  &.note.references,
  &.note.comparision {
    padding: $alert-padding;
    padding-left: $alert-padding * 2;
    margin: $alert-padding 0 !important;
    border: 1px solid $gray-light;
    position: relative;

    &>* {
      margin-left: 45px;
    }

    &:before {
      content: '';
      width: $alert-padding;
      background-color: $gray-light;
      position: absolute;
      height: 100%;
      left: 0;
      top: 0;
    }

    .title {
      margin-top: 0;
      position: relative;
      font-size: 1.1em;
      font-weight: 600;
      text-transform: uppercase;

      &::before {
        content: '';
        font-size: 0.9em;
        position: absolute;
        left: -($alert-padding * 3);
      }
    }

    a {
      word-wrap: break-word;
    }
  }

  &.note {
    @include note($note-default-color, 'admonition-note');
  }

  &.important {
    @include note($note-important-color, 'admonition-important');
  }

  &.warning {
    @include note($note-warning-color, 'admonition-warning');
  }

  &.note.references {
    @include note($note-references-color, 'admonition-reference');
  }

  &.note.comparison {
    @include note($note-comparison-color, 'admonition-note');
  }
}

// }
