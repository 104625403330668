.footer-main {
  position: relative;
  width: 100%;
  padding: 1.25rem 0 0;
  background: #fff;
  font-weight: 600;
  font-size: 12px;
  border-top: 1px solid #ddd;

  .brand {
    margin-top: 1rem;

    &.training {
      margin-bottom: 0.5rem;
      margin-top: 0;
    }

    &.redhat {
      img {
        float: right;
      }
    }
  }

  .anchor {
    padding: 1.25rem 1rem 0.5rem;

    .legal {
      line-height: 1;
      margin-top: 2rem;

      menu {
        margin: 0;
        padding: 0;
        text-align: center;
      }

      a {
        color: #000;

        &:focus,
        &:hover {
          color: black;
          text-decoration: none;
        }
      }

      ul {
        list-style: none;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
        padding: 0;

        li {
          padding: 0 0.5rem 1.5rem 0.5rem;
        }
      }
    }
  }

  &__light-blue-link {
    --pf-v5-c-button--m-secondary--after--BorderColor: #73bcf7;
    --pf-v5-c-button--m-secondary--Color: #73bcf7;
    margin-right: var(--pf-v5-global--spacer--2xl);
  }
}

.chinese-footer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  justify-content: center;
  padding: var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--2xl);
  background-color: var(--pf-v5-global--BackgroundColor--dark-200);
  color: var(--pf-v5-global--Color--light-100);

  & > a {
    color: var(--pf-v5-global--Color--light-100);
    font-size: 14px;
  }
}
