@use 'sass:math';

@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../styles/variables';
@import "../../styles/mixins/mediaqueries";

$icon-width: 45px;

.new-card {
  color: $gray-base;
  margin-bottom: $grid-distance;

  #panel-card {
    margin-bottom: 0;
    display: flex;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .central-panel {
    width: 100%;
  }

  .left-side {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
    border-right: 1px solid #ddd;
    padding-left: $grid-distance * 3;
    padding-right: $grid-distance * 3;

    @media (max-width: $screen-sm-max) {
      display: none;
    }
  }

  .left-side-mobile {
    display: none;

    @media (max-width: $screen-sm-max) {
      display: block;
      border-bottom: 1px solid #ddd;
      padding-top: $grid-distance;
      padding-left: $grid-distance;
      padding-bottom: $grid-distance;
    }
  }

  .right-side {
    border-left: 1px solid #ddd;
    min-width: $grid-distance * 4;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    align-content: center;

    @media (max-width: $screen-sm-max) {
      min-width: $grid-distance * 3;
    }
  }

  .panel-body {
    display: flex;
    flex-direction: column;
  }

  .panel-collapse {
    padding-top: 0;
    padding-left: $grid-distance + $icon-width;

    .panel-body {
      padding-top: 0;
    }
  }

  .head {
    margin: 0 $grid-distance 0 math.div($grid-distance, 2);
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    min-height: 58px;
  }

  .icon {
    display: inline-block;
    vertical-align: middle;
    padding: 5px;
    overflow: hidden;
    position: relative;
    margin-right: $grid-distance;

    .isvg {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }
  }

  .card-title {
    font-weight: bold;
  }

  .body {
    margin: 0 $grid-distance;
    padding-left: $grid-distance + $icon-width;
  }

  .line {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: center;

    @media (max-width: $screen-md-min) {
      flex-direction: column;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-items: stretch;
      align-content: stretch;
    }
  }

  .line>* {
    margin-left: $grid-distance;
    margin-right: $grid-distance;

    &:first-child {
      margin-left: 0;
    }

    @media (max-width: $screen-md-min) {
      margin-left: 0;
      margin-right: 0;
    }
  }

  .second.line {
    margin-top: math.div($grid-distance, 2);
    margin-bottom: math.div($grid-distance, 2);

    @media (max-width: $screen-md-min) {
      border-top: 1px solid #ddd;
      padding-top: math.div($grid-distance, 2);
    }
  }

  .progress {
    margin: 0 0 -1px 0; // Positioning the border of the progress bar aka progress line over the border of the footer
    border-radius: 0;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    height: 25px;

    .progress-bar {
      background-color: transparent !important;
      border-bottom: 3px solid $color-primary;
      text-align: right;
      padding-right: 5px;
      padding-left: 5px;
      min-width: 20px;
      color: black;
      font-weight: 900;
      font-size: 17px;

      &[aria-valuenow='0'] {
        color: $gray-light;
        background: transparent;
        box-shadow: none;
      }
    }
  }

  .no-progress {
    margin: 0;
    margin-top: $form-padding;
    overflow: hidden;
    height: 20px;
  }

  .footer-elements-container {
    display: flex;
    justify-content: space-between;

    @include respond(sm, false) {
      flex-direction: column;
    }

    @include respond(xl, false) {
      .input-group-addon.due-date-text {
        display: none;
      }

      .date-input-group {
        width: 100%;
      }
    }
  }

  .indicators-container {
    margin-top: $grid-distance;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    align-content: stretch;

    @media (max-width: $screen-sm-min) {
      margin-top: 0;
      padding: $grid-distance 0 0 $grid-distance;
      align-items: flex-end;
    }

    @media (max-width: 500px) {
      background-color: #f8f8f8;
      border-bottom: 1px solid #ddd;
    }
  }

  .indicators-container>* {
    margin-left: math.div($grid-distance, 2);
    margin-right: math.div($grid-distance, 2);

    &:first-child {
      margin-left: 0;
    }
  }

  .actions-container {
    margin-top: $grid-distance;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    align-content: stretch;

    @media (max-width: $screen-sm-min) {
      margin-top: 0;
      padding-top: $grid-distance;
      padding-right: $grid-distance;
      padding-bottom: $grid-distance;
      align-items: flex-end;
    }

    a,
    button {
      height: 38px;
      background-color: $color-white;
      padding: 7px 20px 7px 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      color: black;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;

      @include respond(sm, false) {
        width: 100%;
        text-align: center;
        margin: 0 0 5px 10px;
      }
    }

    a:hover,
    .btn-link:hover,
    button:hover {
      text-decoration: none;
      background-color: $gray-lighter;
    }
  }

  .actions-container>* {
    margin-left: math.div($grid-distance, 2);
    margin-right: math.div($grid-distance, 2);

    &:last-child {
      margin-right: 0;
    }
  }

  .panel-footer {
    cursor: default !important;
    padding: 0 $grid-distance $grid-distance;
    border-bottom-right-radius: 0;

    @media (max-width: $screen-sm-min) {
      padding-bottom: 0;
      padding-right: 0;
      padding-left: 0;
    }
  }

  .arrow-icon {
    width: $grid-distance;
  }

  .empty {
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }

  #progress-version-selector {
    background-color: #dddddd;
    color: black;
    border-radius: 4px;
    width: 100px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // This is only for BETA, remove when scheduling all feature is enabled
  a.not-available-btn {
    background-color: #d2d2d2;
    color: #6a6e73;
    text-decoration: none;
  }

  &.grayscale-filter {
    -webkit-filter: grayscale(100%);
    -moz-filter: grayscale(100%);
    filter: grayscale(100%);
  }
}