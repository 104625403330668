@import '../../../styles/variables';

.course-page-result {
  color: $gray;
  border-bottom: 1px solid $gray-lighter;
  padding: 5px 0;

  em {
    font-weight: 600;
  }

  .course-page-highlighted-title {
    font-size: 1.2em;
    font-weight: 600;
    margin-bottom: 5px;
  }
}