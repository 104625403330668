@import '../../styles/variables';

.toc,
.toc-content,
.favorites-list {
  width: 100%;

  a,
  a:link,
  a:visited {
    color: $brand-primary;
    font-weight: 300;
  }

  a:hover,
  a:focus {
    color: $brand-primary-hover;
  }

  .toc_entry_depth_2 {
    background-color: $gray-lighter;

    a,
    a:link,
    a:visited {
      color: $dark-text;
    }

    a:hover,
    a:focus {
      color: darken($dark-text, 15%);
    }
  }

  td {
    padding-top: 10px;
  }

  @for $i from 1 through 3 {
    .toc_entry_depth_#{$i} {
      padding-left: 20px*$i - 20px;
    }
  }

}