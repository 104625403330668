@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

.premiumvt-class {
  .premium-class-elements {
    width: 105%;

    .vertical-timeline::before {
      background: #A5A5A5;
      height: calc(100% - 190px);
      margin-left: -70px;
      margin-top: 72px;

      @media (max-width: $screen-sm-min) {
        height: calc(100% - 230px);
      }
    }

    .vertical-timeline-element-icon {
      box-shadow: 0 0 0 2px #a5a5a5, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 2px rgba(0, 0, 0, 0.05);
      -moz-box-shadow: 0 0 0 2px #a5a5a5, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 2px rgba(0, 0, 0, 0.05);
      -webkit-box-shadow: 0 0 0 2px #a5a5a5, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 2px rgba(0, 0, 0, 0.05);
      background-color: $gray-lightest;
    }

    .vertical-timeline-element.green {
      .vertical-timeline-element-icon {
        background-color: $color-green;
      }
    }
  }

  .premium-class-course-info {
    margin-bottom: $grid-distance * 2;

    .panel-body, .panel-collapse {
      padding-left: $grid-distance;
    }
  }

  #course-details-head {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    background-color: transparent !important;
  }

  #course-details-title {
    color: black;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: stretch;
  }

  #course-details-button {
    color: black;
    font-size: 28px;
  }  
}
