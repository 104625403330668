.organization-invitation-alert {
  margin-top: 32px;

  .inline {
    margin-top: 20px;

    .form-group button {
      margin-right: 4px;
    }

    .form-group.opt-out {
      margin-left: 10px;

      label {
        font-weight: normal;
        margin-left: 3px;
      }
    }
  }
}
