.image-previewer {
  &__image {
    background-color: white;
    padding: 10px;
  }

  &__preview {
    cursor: pointer;
  }

  &__overlay {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 100;
  }
}