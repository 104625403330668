@import '~bootstrap-sass/assets/stylesheets/bootstrap/variables';
@import '../../../../styles/variables';

.add-user-custom-skill-path-modal {
  .add-user-modal-footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .add-user-modal-header {
    display: flex;
    align-items: baseline;

    .org-name-list {
      display: flex;
      margin: 10px;
      flex-wrap: wrap;

      .checkbox-inline {
        border: 0px;
        border-left: 1px solid #ccc;
        padding: 10px;

        input[type='checkbox'] {
          position: inherit;
          margin-left: 0px;
        }
      }

      .org-name-group {
        display: flex;
        align-items: center;
        border: 1px solid #ccc;

        margin-left: 15px;
        margin-bottom: 15px;

        span {
          padding: 10px;
        }
      }
    }
  }

  .add-user-modal-body-confirm-message {
    padding: 15px;
  }
  
  .add-user-modal-body {
    .users-to-add-filter {
      .users-to-add-filter-form {
        border-top: 1px solid #cccc;
        border-bottom: 1px solid #cccc;
        padding: 15px;
      }
    }

    .users-to-add-list {
      .row-user-to-add-header {
        line-height: 20px;

        .column-user {
          cursor: pointer;

          span {
            font-weight: bold;
          }
        }

        @media (max-width: $screen-sm-max) {
          display: none !important;
        }
      }

      .row-user-to-add {
        display: flex;
        border-left: 0px solid #cccc;
        border-right: 0px solid #cccc;
        border-top: 0px solid #cccc;
        border-bottom: 1px solid #cccc;
        cursor: pointer;
        align-items: center;

        @media (max-width: $screen-sm-max) {
          flex-direction: column;
        }

        .column-user {
          white-space: -moz-pre-wrap !important; /* Mozilla, since 1999 */
          white-space: -webkit-pre-wrap; /* Chrome & Safari */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          white-space: pre-wrap; /* CSS3 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
          word-break: break-all;
          white-space: normal;

          flex-basis: 100%;
          flex: 1;

          margin-right: auto;
          padding: 2px;

          .row-sm-header {
            display: none;

            font-weight: lighter;

            @media (max-width: $screen-sm-max) {
              display: inline;
            }
          }
        }

        .check-column {
          flex: 0.05;
        }
      }
    }
  }
}
