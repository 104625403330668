@import '../../../styles/mixins/mediaqueries';

.course-language-btn {
  #course-language-dropdown {
    @include respond(md, false) {
      width: 100%;
    }
  }

  .dropdown-toggle {
    font-size: 100%;
    font-weight: normal;
    text-transform: none;

    @include respond(md, false) {
      width: 100%;
    }
  }

  .btn-group {
    @include respond(md, false) {
      width: 100%;
      margin-bottom: 0.625rem;
    }
  }
}