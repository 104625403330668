.lesson-indicators {
  display: inline-flex;
  flex-direction: column;

  & p {
    margin-top: 0;
  }

  &__tags {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
