.filter-group {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--pf-v5-global--Color--light-300);

  // workaround bootstrap CSS
  label, input[type=radio], input[type=checkbox] {
    margin-bottom: 0;
    margin-top: 0;
  }

  &__header {
    display: flex;
    flex-direction: row;
    background-color: #5f526d;
    color: var(--pf-v5-global--palette--white);
    align-items: center;
    justify-content: space-between;
    padding: 12px 14px;
  }

  &__text-filter-container {
    padding: var(--pf-v5-global--spacer--md) var(--pf-v5-global--spacer--lg);
    border-bottom: 1px solid var(--pf-v5-global--Color--light-300);

    & > p {
      margin: 0;
      margin-bottom: var(--pf-v5-global--spacer--xs);
    }
  }

  &__title {
    text-transform: uppercase;
    font-weight: 400;
  }

  &__clear-btn {
    background-color: var(--pf-v5-global--palette--white);
    color: black;
    border: none;

    &:hover {
      background-color: var(--pf-v5-global--BackgroundColor--light-300);
    }
  }
}

.filter-group-filter {
  position: relative;

  &:not(:last-child) {
    & > .filter-group-filter__header {
      &:after {
        position: absolute;
        content: '';
        border-bottom: 1px solid var(--pf-v5-global--Color--light-300);
        margin-left: 15px;
        display: block;
        width: calc(100% - 30px);
        bottom: 1px;
        left: 0px;
      }
    }
  }

  &__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-transform: uppercase;
    padding: 10px 16px;
    transition: background-color 0.1s ease-out;

    &:hover  {
      color: var(--pf-v5-global--palette--black-600);
    }

  }

  &__title {
    padding: 0;
    margin: 0;
  }

  &__arrow-icon {
    display: flex;
    justify-content: center;
    align-items: center;


    & > svg {
      width: 16px;
      height: 16px;
    }
  }

  &__options {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;
    margin: 0;
    padding: 0 16px;

    &--expanded {
      max-height: 1000px;
      transition: max-height 0.4s ease-in;
    }
  }

  &__option {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}
