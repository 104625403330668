@import "../../../styles/variables";

.lab-error-modal {
  .modal-header {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border: none;

    .lab-error-modal-title {
      font-weight: bold;
      color: #d40000;
    }

    .lab-error-modal-show-details-btn {
      width: 200px;
    }
  }

  .modal-body {
    .lab-error-table-wrapper {
      height: 300px;
      overflow-y: auto;
      display: grid;
      grid-template-columns: repeat(5, 1fr);

      .table-header {
        position: sticky;
        top: 0;
        background-color: #fff;
        border-bottom: 2px solid #ddd;
      }

      & > span {
        padding: 8px 4px;
      }

      .table-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ddd;

        &:nth-last-child(-n + 5) {
          border: none;
        }
      }
    }
  }
}
