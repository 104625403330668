.cancel-session-modal-dialog {
  .modal-content,
  .modal-body,
  .modal-header {
    border: none;
    border-radius: 0;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
  }

  .modal-title {
    color: rgb(101, 101, 101);
  }

  .close-btn {
    background-color: #08c0fc;
    color: #ffffff;
    border: 1px solid #08c0fc;
    border-radius: 0.25rem 0.25rem;
  }

  .clear-btn {
    background-color: #ffffff;
    color: black;
    border: 1px solid black;
    border-radius: 0.25rem 0.25rem;
    margin-right: 15px;
    margin-left: 15px;
    text-align: right;
  }

  .success-message {
    color: #4caf50;
  }

  .error-message {
    color: red;
  }

  .model-wrapper {
    .progress-message {
      display: flex;
    }

    .progress-message-button {
      display: flex;
      justify-content: flex-end;
      margin-top: 15px;

      .button-close {
        margin-right: 15px;
      }

    }
    @media (min-width: 576px) {
      .progress-form {
        min-width: 570px;
      }
    }
  }
}
