@import '../../../../../styles/variables';

.mgmt-exam-table {
  .custom-table-header-main {
    --pf-v5-ctable--cell--MinWidth: 100px;
  }

  .custom-table-header {
    background-color: $color-secondary-light;
    color: $color-white;
    --pf-v5-ctable--cell--MinWidth: 100px;
  }
}
